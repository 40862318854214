import React, {useState, useEffect} from "react";
import { useOktaAuth } from '@okta/okta-react';
import "./styles.scss";
import { Icon } from "../../../packages/ui-kit";
import logo from "../../assets/arc-logo-wht.svg";
import { OKTA_SIGNOUT_REDIRECT_URL } from "../../../packages/auth/constants/okta";


export const Header = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<any>(null);
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const signOut = async () => {
    // NOTE:  Different environments wil have different signout redirects
    // Development will go back to /settlements/search and will then enforce a sign in by redirecting to oktapreview.com
    // Production and maybe UAT will go back to MyARC for sign in
    if (window.sessionStorage.getItem("initialExpireState") !== undefined)
    {
      window.sessionStorage.removeItem("initialExpireState");
    }
    
    await oktaAuth.signOut({ postLogoutRedirectUri: OKTA_SIGNOUT_REDIRECT_URL });
  };

  return (
    <header>
      <div className="logo">
        <img className="logo-image" src={logo} alt="arc logo" />
        <span> Financial Center</span>
      </div>
      <div className="right d-print-none">
        <span className="icon-bell"><Icon.Bell /></span>
        <span className="username">{userInfo?.preferred_username}</span>
        {/* eslint-disable-next-line */}
        <a className="header-link no-style">Help</a>
        {
          authState?.isAuthenticated ?
         // eslint-disable-next-line
         <a className="header-link no-style" data-test-id="sign-out" onClick={signOut}>Sign Out</a> : null
        }
      </div>
    </header>
  );
};

export default Header;
