import React from "react";
import { Form } from "react-bootstrap";

export interface CheckboxProps {
  text: string;
  id: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  defaultChecked?: boolean;
  checked?: boolean;
  type?: "switch" | "checkbox" | "radio" | undefined;
  disabled?: boolean;
}

const Checkbox = (props: CheckboxProps) => (
  <Form.Check
    label={props.text}
    id={props.text}
    onChange={props.onChange}
    defaultChecked={props.defaultChecked}
    checked={props.checked}
    name={props?.name}
    type={props?.type}
    disabled={props?.disabled}
  />
);

export default Checkbox;
