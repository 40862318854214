import { createSlice, Action } from "@reduxjs/toolkit";

export const documentDetails = createSlice({
  name: 'DocumentDetails',
  initialState: {
    hasModifications: false,
  },
  reducers: {
    hasModifications: (state, _: Action) => ({ ...state, hasModifications: true }),
    resetHasModifications: (state, _: Action) => ({ ...state, hasModifications: false })
  }
});

export const { hasModifications, resetHasModifications } = documentDetails.actions;
