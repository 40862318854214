import React, { useEffect, useState } from "react";
import { Modal, ProgressBar, Button } from "react-bootstrap";
import "./TimeoutModal.scss";
import { DateTime } from "luxon";


export interface TimeoutModalArgs {
  visible?: boolean;
  handleShowTimeoutModal: (flag: boolean) => void;
} 

const TIMEOUT_DURATION = 1000 * 60 * 2;

const TimeoutModal = (props: TimeoutModalArgs) => {
  const { handleShowTimeoutModal, visible } = props;
  const [timeLeft, setTimeLeft] = useState(TIMEOUT_DURATION);
  const [progress, setProgress] = useState(100);
  

  useEffect(() => {
    let timer: any;

    if(visible && timeLeft >= 0){
      timer = setInterval(() => {
        setTimeLeft(timeLeft - 1000);
        setProgress(parseInt((timeLeft/TIMEOUT_DURATION * 100).toFixed(2)));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [visible, progress, timeLeft]);

  const handleClose = () => {
    setTimeLeft(TIMEOUT_DURATION);
    setProgress(100);
    handleShowTimeoutModal(false);
  };

  return (
    <Modal show={visible}  onHide={() => {handleClose();}} dialogClassName="timeOutModal" >
      <Modal.Header closeButton>
        <Modal.Title>
          Your Document Search session is about to expire.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeOutModalBody type-body-primary-on-light">
        For security purposes, you will be automatically logged out due to inactivity. Any incomplete data input during this session may be lost. To continue your session, select "Stay Logged In".
        </div>
      <ProgressBar className="type-body-primary-on-light" now={progress} max={100} label={DateTime.fromMillis(timeLeft).toFormat("mm'm' ss's'")}/>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <Button variant="secondary">Close Document Search</Button>
          <Button onClick={() => {handleClose();}}>Stay Logged In</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimeoutModal;
