import React from "react";
import "./styles.scss";

const MaxSessionTimeout = () => {
  return (



<div className="timeout-wrapper">
  <div className="timeout-alert">
<i className="fds-glyphs fds-glyphs-info2 info"/>
      <span className="timeout-message-content type-body-primary-on-light-strong">
  Your Document Search session has ended. Please close this window.
</span>
</div>
</div>);
};

export default MaxSessionTimeout;
