import {
  ApolloProvider,
} from "@apollo/client";
import * as FullStory from "@fullstory/browser";
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Router from "./portal/router";
import { Provider } from "react-redux";
import { store } from "./store";

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import {
  clientId,
  OKTA_LOGIN_CALLBACK_URI,
  OKTA_ISSUER_URL,
  OKTA_SCOPES,
  OKTA_IS_PKCE
} from "./packages/auth/constants/okta";

import apolloClient from './apolloClient';
const skipLogin = process.env.REACT_APP_SKIP_LOGIN === "true";


const oktaAuth = new OktaAuth({
  clientId: clientId,
  issuer: OKTA_ISSUER_URL,
  redirectUri: OKTA_LOGIN_CALLBACK_URI,
  scopes: OKTA_SCOPES,
  pkce: OKTA_IS_PKCE,
  storageManager: {
    token: {
      storageType: 'sessionStorage',
      storageTypes: [], // enforce session
    },
    transaction: {
      storageType: 'sessionStorage',
      storageTypes: [], // enforce session
    },
    cache: {
      storageType: 'sessionStorage',
      storageTypes: [], // enforce session
    }
  },

  // Configure TokenManager to use sessionStorage instead of localStorage
  tokenManager: {
    storage: 'sessionStorage',
  },
});

const MyApp = () => {

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
  logLevel: "ERROR",
  datafileOptions: {
    urlTemplate: '/optimizely/%s',
  }
});

if (skipLogin) {
  //when running with mock data, enable all features
  optimizely.isFeatureEnabled = () => true;
}
  
  const [userInfo] = useState<any>(null);

  if (userInfo?.email) {
    FullStory.setUserVars({ email: userInfo.email });
  }
  
  return (
    <OptimizelyProvider 
      optimizely={optimizely} 
      user={

        // skipLogin supports local testing with the mock server and also 
        // needed so optimizely can evaluate flags based on a user
        // in future, if we can mock out optimizely, we could maybe remove this
        skipLogin?
          {
            id: "123456", 
            attributes: { email: "nologin@arccorp.com" }
          } 
          : (
            userInfo?
              { id: userInfo?.sub || "anonymous", 
                attributes: { email: userInfo?.email?.toLowerCase() }
              } 
              : {id: "anonymous"}
          )
        
      }>
      <ApolloProvider client={apolloClient}>
        <Router />
      </ApolloProvider>
    </OptimizelyProvider>
  );
};

// Full Story Library activation
FullStory.init({ orgId: 'ZENCN' });

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  window.location.replace( toRelativeUrl(originalUri || '/', window.location.origin) );
};

// tslint:disable-next-line:no-var-requires
ReactDOM.render(
  <Provider store={store}>
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <MyApp />
    </Security>
  </Provider>,
  document.getElementById("root"),
);
