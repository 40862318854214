import React from "react";
import classNames from "classnames";
import "./Card.scss";

export interface CardPropTypes {
  title?: string;
  children: React.ReactNode;
  className?: string;
}

const Card = (props: CardPropTypes) => (
  <div className={classNames("fds-card card", props.className)}>
    {props.title ? <h1 className="type-heading-h1">{props.title}</h1> : null}
    {props.children}
  </div>
);

export interface FlushProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flushBottom?: boolean;
}

const Flush = (props: FlushProps) => {
  return (
    <div className={classNames("flush-card", props.className, props.flushBottom ? "flush-bottom" : undefined)}>{props.children}</div>
  );
};

Card.Flush = Flush;
export default Card;
