import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { useDispatch } from 'react-redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { printOptions } from './printOptions/index';
import { documentDetails } from './documentDetails/index';


export const rootReducer = combineReducers({
  documentDetails: documentDetails.reducer,
  printOptions: printOptions.reducer,
});
export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({ reducer: rootReducer });
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

