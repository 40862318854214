import { DateTime } from "luxon";
import { TimeZone} from "@arc/common-settlement-utils/lib/constants";
/**
 * Formats a date in mm/dd/yy format.
 * @param date Datetime to format
 */
export function formatDateString(date: Date | undefined): string {
  if (date === undefined) {
    return "";
  }
  return `${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}/${date.getDate() < 10 ? "0" : ""}${date.getDate()}/${date.getFullYear().toString().slice(2, 4)}`;
}

export function formatDateFromIsoString(isoDate?: string | null): string {
  return isoDate && DateTime.fromISO(isoDate).isValid ? DateTime.fromISO(isoDate, { setZone: true, zone: "utc" }).toFormat("LL/dd/yyyy") : "—";
}

/**
 * Formats soldFlightDepartureTime to hh:mm AM/PM format
 * @param time string to format
 */
export function formatSoldFlightDepartureTime (time: string | null | undefined): string {
  if (!(time && time.length === 5)) {
    return "——:——";
  }
  const [hours, minutes] = time.split(":", 2);
  return `${hours === "12" || hours === "00" ? "12" : +hours % 12}:${minutes} ${+hours > 11 ? "PM" : "AM"}`;
}

/**
 * Formats soldFlightDepartureDate to MM/dd/yy format
 * @param date string to format
 */
export function formatSoldFlightDepartureDate (date: string | null | undefined): string {
  if (!(date && date.length === 10 && (date.includes("/") || date.includes("-")))) {
    return "——/——/——";
  }
  const separator = date.includes("/") ? "/" : "-";
  const [year, month, day] = date.split(separator, 3);
  return `${month}/${day}/${year.slice(2,4)}`;
}

/**
 * Returns a formatted next date
 * @param date Date to start from
 */
export function generateNextDayString(date: Date): string {
  const nextDay = date;
  nextDay.setDate(date.getDate() + 1);
  return formatDateString(nextDay);
}

/**
 * Returns Date of the current day
 */
export function getToday(): Date {
  const today = new Date();
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  today.setHours(0);
  return today;
}

/**
 * Checks if input is a valid date
 */
export function isValidDate(value: string | number): boolean {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

/**
 * Returns weeks end Sunday date 
 */
export function formatSettledDate() {
  const systemDate = DateTime.local().setZone(TimeZone.Eastern);
  const endOfWeekDate = systemDate.endOf("week");

  //check if current date is Sunday
  const result = systemDate.day !== 7 ? endOfWeekDate.toFormat("MM/dd/yy") : systemDate.toFormat("MM/dd/yy");
  
  return result as string;
}