export const clientId = process.env.REACT_APP_OKTA_CLIENT_ID || "0oa120ukagucurEuQ0h8";
export const baseUrl = process.env.REACT_APP_OKTA_BASE_URL || "https://myarc.oktapreview.com";
export const OKTA_LOGIN_CALLBACK_URI = process.env.REACT_APP_OKTA_LOGIN_CALLBACK_URI || "/login/callback";
export const OKTA_TOKEN_NAME = // Cypress will require its own space as Okta uses okta-token-storage
    // @ts-ignore
    window.Cypress ? "okta-cypress-token-storage" : "okta-token-storage";
export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER || "auskmtw2jouqA4Dwf0h7";
export const OKTA_ISSUER_URL = `${baseUrl}/oauth2/${OKTA_ISSUER}`;
export const OKTA_SCOPES = process.env.REACT_APP_OKTA_SCOPES?.split(",") || ["openid", "profile", "email", "settlement:api_access"];
export const OKTA_IS_PKCE = JSON.parse(process.env.REACT_APP_OKTA_IS_PKCE || "false");
export const OKTA_SIGNOUT_REDIRECT_URL = process.env.REACT_APP_OKTA_SIGNOUT_REDIRECT_URL || "/";
export const OKTA_SESSION_EXPIRED_REDIRECT_URL = process.env.REACT_APP_OKTA_SESSION_EXPIRED_REDIRECT_URL || "/session-expired";