import React from "react";
import Icon from "../Icon";
import "./CheckmarkCircle.scss";

const CheckmarkCircle = () => {
  return (
    <div className="circle">
      <Icon.Checkmark color="white" />
    </div>
  );
};
export default CheckmarkCircle;
