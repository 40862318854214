import classnames from "classnames";
import React from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";

export enum InputType {
  text = "text",
  textarea = "textarea",
}

export interface InputPropTypes {
  type: InputType;
  value?: string;
  label?: JSX.Element[] | JSX.Element | string;
  append?: JSX.Element[] | JSX.Element | string;
  subText?: JSX.Element[] | JSX.Element | string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  size?: "sm" | "lg";
}

const Input = (props: InputPropTypes) => (
  <div
    className={classnames("fds-text-input", {
      [props.className as string]: true,
      [props.size || ""]: true,
      "input-has-append": props.append,
    })}
  >
    <InputGroup size={props.size}>
      <FormControl
        onBlur={props.onBlur}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
      />
      {props.append && (
        <InputGroup.Append>
          <InputGroup.Text>{props.append}</InputGroup.Text>
        </InputGroup.Append>
      )}
      {props.subText ? (
        <Form.Text className="text-muted">{props.subText}</Form.Text>
      ) : null}
    </InputGroup>
  </div>
);

Input.defaultProps = {
  type: "text",
};

export default Input;
