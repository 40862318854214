import React from "react";
import { Form } from "react-bootstrap";
import "./FormStaticText.scss";

export interface FormStaticTextProps {
  label: string;
  text: string | undefined;
}

const FormStaticText = (props: FormStaticTextProps) => {
  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control className="form-static-text" readOnly={true} plaintext={true} value={props.text} disabled={true} /> 
    </>
  );
};

export default FormStaticText;
