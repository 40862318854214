import results from "./generated";

export const possibleTypes = () => {
  const possibleTypes: any = {};
  results.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] =
        supertype.possibleTypes.map(subtype => subtype.name);
    }
  });
  return possibleTypes;
};
