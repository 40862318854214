import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHotkeys } from "react-hotkeys-hook";
import { Text, TextType } from "../../../packages/ui-kit";
import { LoadingSpinner } from "@arctravel/react-fds";
import { gql } from "@apollo/client";
import VersionInfo from "./VersionInfo";
import { useVersionQuery } from "../../../packages/settlement-graph";
import "./DebugModal.scss";


const DebugModal = () => {
  const { loading, data } = useVersionQuery();
  const [visible, setVisible] = useState<boolean>(false);
  const handleClose = () => {
    setVisible(false);
  };
  useHotkeys("ctrl+shift+/", () => {
    setVisible(true);
  });

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Debug Utils
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? <div className="paddingTop"><LoadingSpinner /></div> :
          <Text>
            {Object.entries(process.env).map(([key, info]) => info ?
              <div key={key} style={{ paddingBottom: 8 }}>
                <Text type={TextType.bodyPrimaryStrong}>{key}</Text>
                <div style={{ paddingLeft: 16 }}>
                  <Text>{info}</Text>
                </div>
              </div> : null)}
            <hr />
            {data?.versions.map(service => service?.serviceName !== "unknown" ?
              <VersionInfo key={service.serviceName} service={service} /> : null)}
          </Text>
        }
      </Modal.Body>
    </Modal>
  );
};

DebugModal.query = gql`
    query Version {
        versions {
            ...VersionInfo
        }
    }
    ${VersionInfo.fragments.versionInfo}
`;

export default DebugModal;
