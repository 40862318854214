import { ApolloClient, ApolloLink, FieldPolicy, HttpLink, InMemoryCache, NormalizedCacheObject, Reference, ServerError } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import { possibleTypes } from "./packages/settlement-graph";
import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import { datadogRum } from "@datadog/browser-rum";

function fromSizePagination<T = Reference>(keyArgs = ["airlineNumCode", "arcNumber", "documentNumbers",
  "dateOfIssue", "orderDocumentTypes", "passengerLastName", "paymentType", "recordLocator",
  "reportingPeriod", "settledDate", "transactionType"]): FieldPolicy<{ data: T[] }> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing?.data ? existing?.data.slice(0) : [];
      const start = args ? args.from : merged.length;
      const end = start + incoming.data.length;
      for (let i = start; i < end; ++i) {
        merged[i] = (incoming as any).data[i - start];
      }
      return { ...incoming, data: merged };
    },
  };
}

const dataDogAppId = process.env.REACT_APP_DATA_DOG_APP_ID;
const dataDogBrowserToken = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN || "local";
const domainUrl = "https://"+process.env.REACT_APP_DOMAIN_URL;

class Network {
    private static instance: Network;
    public apolloClient: ApolloClient<NormalizedCacheObject>;
    private constructor(){

        if (dataDogAppId) {
            datadogRum.init({
              applicationId: dataDogAppId,
              clientToken: dataDogBrowserToken,
              version: process.env.REACT_APP_COMMIT,
              env: process.env.NODE_ENV,
              sampleRate: 100,
              trackInteractions: true,
              service: "settlement_portal",
              allowedTracingOrigins: [domainUrl]       
            });
          }
          
          datadogLogs.init({
            clientToken: dataDogBrowserToken,
            datacenter: "us",
            service: "arc_web",
            env: process.env.NODE_ENV,
            forwardErrorsToLogs: true,
            sampleRate: 100
          });

          if (dataDogBrowserToken === "local") {
            datadogLogs.logger.setHandler(HandlerType.console);
            datadogLogs.logger.log(`Local development: logs will not be sent to datadog`);
          } else if (navigator?.userAgent?.toLowerCase()?.includes("cypress")) {
            datadogLogs.logger.setHandler(HandlerType.console);
            datadogLogs.logger.log(`Automated testing: logs will not be sent to datadog`);
          }
          
        let token;
        
        const withToken = setContext(() => {
            const t = window.sessionStorage.getItem("okta-token-storage");
            let storedToken;
            if (t)
            {
                storedToken = JSON.parse(t).accessToken.accessToken;
            }
   
            token = `Bearer ${storedToken}`;
            
            return { headers: { authorization: token } };
          });

          const resetToken = onError(({ networkError }) => {
            if (
              networkError &&
              networkError.name === "ServerError" &&
              (networkError as ServerError).statusCode === 401
            ) {
              // remove cached token on 401 from the server
              token = null; 
            }
          });

          const authFlowLink = withToken.concat(resetToken);

          const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
            if (graphQLErrors)
              graphQLErrors.map(({ message }) =>
                datadogLogs.logger.error(
                  `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Variables: ${JSON.stringify(operation.variables)}`
                )
              );
            if (networkError) datadogLogs.logger.error(`[Network error]: ${networkError}, Operation: ${operation.operationName}, Varibles: ${JSON.stringify(operation.variables)}`);
          });
                
        this.apolloClient = new ApolloClient({
            cache: new InMemoryCache({
              possibleTypes: possibleTypes(),
              typePolicies: { Query: { fields: { searchedItems: fromSizePagination() } } },
            }),
            link: ApolloLink.from([
                errorLink,
                authFlowLink as any,
                new HttpLink({
                  credentials: "same-origin",
                  uri: process.env.REACT_APP_GRAPH_API,
                }),
              ]),
            defaultOptions: {
              watchQuery: {
                errorPolicy: 'all'
              }
            }
          });
    }

    public static getInstance(): Network {

        if (!Network.instance) {
            Network.instance = new Network();
        }

        return Network.instance;
    }
}

export default Network.getInstance().apolloClient;


