import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateString } from "../../../utils";
import { Icon } from "../../index";
import "./date-picker.scss";


export interface CustomDatePickerProps {
  onChange: (input: string) => void;
  isInvalid?: boolean;
  dateFilter?: (date: Date)  => boolean;
  initialValue?: string;
  type?: string;
  placeholder?: string;
  "aria-label"?: string;
  maxDate?: Date;
  onlySingleDate?: boolean;
}

const CustomDatePicker = ({onChange, dateFilter, isInvalid = false, initialValue, type, placeholder, onlySingleDate = false, maxDate = new Date(), ...props}: CustomDatePickerProps) => {
  const SINGLE_DATE = "Date (Single)";
  const DATE_RANGE = "Date (Range)";

  const initialDates = initialValue?.split(" - ");

  const minDate = DateTime.fromJSDate(new Date()).toUTC().minus({ months: 3, years: 3 }).toJSDate();
  const [dateInputType, setDateInputType] = useState(initialDates?.length === 2 && !onlySingleDate? DATE_RANGE: SINGLE_DATE);
  const [selectDate, setSelectDate] = useState<Date | undefined>((initialDates && initialDates.length === 1)? new Date(initialDates[0]): undefined);
  const [selectStartDate, setSelectStartDate] = useState<Date | undefined>((initialDates && initialDates.length === 2) && !onlySingleDate? new Date(initialDates[0]): undefined);
  const [selectEndDate, setSelectEndDate] = useState<Date | undefined>((initialDates && initialDates.length === 2) && !onlySingleDate ? new Date(initialDates[1]): undefined);
  const [isStShow, setIsStShow] = useState(true);
  const stRef = useRef(null);
  const enRef = useRef(null);

  const daysToRestrict = 7;

  const value = dateInputType === SINGLE_DATE ?
    (selectDate ? formatDateString(selectDate) : "") :
    ((selectStartDate && selectEndDate) ? formatDateString(selectStartDate) + " - " + formatDateString(selectEndDate) : "");

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line
  },[value]);

  useLayoutEffect(() => {
    if (isStShow) {
      if (stRef && stRef.current) {
        // @ts-ignore
        stRef.current.focus();
      }
    } else {
      if (enRef && enRef.current) {
        // @ts-ignore
        enRef.current.focus();
      }
    }
  }, [stRef, enRef, isStShow]);

  const DateInput = (
    <div className="dropdown-container">
      <Dropdown className="fds-dropdown">
        <Dropdown.Toggle id="dropdown-basic">
          {dateInputType}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setDateInputType(SINGLE_DATE)}>{SINGLE_DATE}</Dropdown.Item>
          { !onlySingleDate ? <Dropdown.Item onClick={() => setDateInputType(DATE_RANGE)}>{DATE_RANGE}</Dropdown.Item> : null}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const SingleDateEls = (
    <div className="dateWrapper">
      <div className="row top-margin">
        <input className="form-control" readOnly={true} placeholder="Date" type="text" value={formatDateString(selectDate)}/>
      </div>
      <div className="row">
        <DatePicker
          id="DateSelector"
          className="form-control type-body-primary-on-light"
          dateFormat="MM/dd/yy"
          filterDate={dateFilter}
          selected={selectDate}
          isClearable={false}
          inline
          minDate={minDate}
          maxDate={maxDate}
          onChange={(data: Date) => {
            setSelectDate(data);
          }}
        />
      </div>
    </div>
  );

  const clearDates = () => {
    setSelectStartDate(undefined);
    setSelectEndDate(undefined);
    setIsStShow(true);
  };

  const DateRangeEls = (
    <div className="dateWrapper">
      <div className="clear">
        <button type="button" className="btn btn-link" onClick={() => clearDates()}>Clear Date Range</button>
      </div>
      <div className="row">
        <input className="form-control flex" type="text" name="rangeSIP" ref={stRef} placeholder="Start"
               onFocus={() => setIsStShow(true)} value={formatDateString(selectStartDate)} readOnly={true}/>
        <span className="dash">—</span>
        <input className="form-control flex" type="text" name="rangeEIP" ref={enRef} placeholder="End"
               onFocus={() => setIsStShow(false)} value={formatDateString(selectEndDate)} readOnly={true}/>
      </div>
      <div className="row">
        <DatePicker
          id="startDateSelector"
          className="form-control type-body-primary-on-light"
          dateFormat="MM/dd/yy"
          selected={isStShow ? selectStartDate : selectEndDate}
          disabled={false}
          filterDate={dateFilter}
          startDate={selectStartDate}
          endDate={selectEndDate}
          isClearable={false}
          inline
          minDate={type === "settledDt" ? (selectEndDate ? DateTime.fromJSDate(selectEndDate).minus({days: daysToRestrict}).toJSDate() : (selectStartDate ? DateTime.fromJSDate(selectStartDate).minus({days: 1}).toJSDate() : minDate)) : minDate}
          maxDate={type === "settledDt" ? (selectStartDate ? DateTime.fromJSDate(selectStartDate).plus({days: daysToRestrict}).toJSDate() : (selectEndDate ?  DateTime.fromJSDate(selectEndDate).plus({days: 1}).toJSDate() : maxDate)) : maxDate}
          onChange={(data: Date) => {
            if (isStShow) {
              setSelectStartDate(data);
              if(type === "settledDt") {
                setSelectEndDate(DateTime.fromJSDate(data).plus({days: daysToRestrict}).toJSDate());
              }
              setIsStShow(false);
            } else {
              setSelectEndDate(data);
              if(type === "settledDt") {
                setSelectStartDate(DateTime.fromJSDate(data).minus({days: daysToRestrict}).toJSDate());
              }
            }
          }}
        />
      </div>
    </div>
  );

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        {DateInput}
        {dateInputType === SINGLE_DATE ? SingleDateEls : DateRangeEls}
      </Popover.Content>
    </Popover>
  );

  return (
    <React.Fragment>
      <OverlayTrigger
        trigger="click"
        placement="auto-start"
        overlay={popover}
        rootClose={true}
        rootCloseEvent="click"
      >
        <div className="input-container">
          <input className={classNames("form-control", "date-input", {"is-invalid": isInvalid})} readOnly={true} type="text" role="dialog" aria-label={props["aria-label"]}
                 placeholder={placeholder || "Enter Date or Date Range"} value={value}/><Icon.Calendar/>
        </div>
      </OverlayTrigger>
    </React.Fragment>
  );
};

export default CustomDatePicker;
