import classNames from "classnames";
import * as React from "react";

export enum TextType {
  h1Xl = "h1Xl",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h3Secondary = "h3Secondary",
  body = "body",
  bodyPrimaryStrong = "bodyPrimaryStrong",
  bodySecondary = "bodySecondary",
  bodySmall = "bodySmall",
  small = "small",
  accent = "accent",
  caption = "caption",
  error = "error",
  disabled = "disabled",
  disabledStrong = "disabledStrong"
}

export interface TextProps {
  type: TextType;
  children?: React.ReactNode;
  className?: string;
  onDark?: boolean;
}

const getClassNameFromProps = ({ type, onDark }: TextProps) => {
  switch (type) {
    case TextType.h1Xl:
      if (onDark) {
        console.error("onDark is not supported for TextType.h1Xl");
      }
      return "type-heading-h1-xl";
    case TextType.h1:
      return onDark ? "type-heading-h1-on-dark" : "type-heading-h1";
    case TextType.h2:
      return onDark ? "type-heading-h2-on-dark" : "type-heading-h2";
    case TextType.h3:
      return onDark
        ? "type-heading-h3-primary-on-dark"
        : "type-heading-h3-primary";
    case TextType.h3Secondary:
      return onDark
        ? "type-heading-h3-secondary-on-dark"
        : "type-heading-h3-secondary";
    case TextType.body:
      return onDark
        ? "type-body-primary-on-dark"
        : "type-body-primary-on-light";
    case TextType.bodySecondary:
      return onDark
        ? "type-body-secondary-on-dark"
        : "type-body-secondary-on-light";
    case TextType.small:
      return onDark
        ? "type-heading-small-on-dark"
        : "type-heading-small-on-light";
    case TextType.bodyPrimaryStrong:
      if (onDark) {
        console.error("onDark is not supported for TextType.accent");
      }
      return "type-body-primary-on-light-strong";
    case TextType.bodySmall:
      if (onDark) {
        console.error("onDark is not supported for TextType.accent");
      }
      return "type-body-small-on-light";
    case TextType.accent:
      if (onDark) {
        console.error("onDark is not supported for TextType.accent");
      }
      return "type-heading-accent";
    case TextType.caption:
      if (onDark) {
        console.error("onDark is not supported for TextType.accent");
      }
      return "type-caption-on-light";
    case TextType.error:
      return "type-caption-error";
    case TextType.disabled:
      return "type-body-secondary-on-light-disabled";
    case TextType.disabledStrong:
      return "type-body-secondary-on-light-disabled-strong";
    default:
      return onDark
        ? "type-heading-body-primary-on-dark"
        : "type-heading-body-primary-on-light";
  }
};

const Text = ({ className, children, type, onDark, ...props }: TextProps) => {
  const containerClassName = getClassNameFromProps({ type, onDark });

  return (
    <div className={classNames(className, containerClassName)} {...props}>
      {children}
    </div>
  );
};

Text.defaultProps = {
  onDark: false,
  type: TextType.body,
};

export default Text;
