import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const printOptions = createSlice({
  name: 'PrintOptions',
  initialState: {
    commission: true,
    passenger: true
  },
  reducers: {
    updatePrintOptions: (state, action: PayloadAction<{ commission: boolean, passenger: boolean }>) => ({ ...state, ...action.payload }),
  }
});

export const { updatePrintOptions } = printOptions.actions;
