import dineroFactory, { Dinero, DineroObject } from "dinero.js";
import { Money } from "../settlement-graph";

export const formatDollarAmount = (
  money:
    | Pick<Money, "amount" | "currency" | "precision">
    | Dinero
    | null
    | undefined,
) => {
  if (!money) {
    return "$0.00";
  }
  const dinero = dineroFactory(money as DineroObject);
  return dinero.isNegative()
    ? `(${dinero.toFormat().replace("-", "")})`
    : dinero.toFormat();
};

export const sumMoneyList = (
  moneyList: Array<
    Pick<Money, "amount" | "currency" | "precision"> | null | undefined
  >,
): Money | null => {
  if (moneyList.length === 0) {
    return null;
  }
  const totalDinero = moneyList
    .filter((m) => m !== null)
    .filter((m) => m !== undefined)
    .reduce(
      (total, money) => {
        const dinero = dineroFactory(money as DineroObject);
        return total.add(dinero);
      },
      dineroFactory({
        ...moneyList[0],
        amount: 0,
      } as DineroObject) as dineroFactory.Dinero,
    );
  return {
    amount: totalDinero.getAmount(),
    currency: totalDinero.getCurrency(),
    precision: totalDinero.getPrecision(),
  } as Money;
};

export const dineroToMoney = (dinero: Dinero): Money => {
  return {
    amount: dinero.getAmount(),
    currency: dinero.getCurrency(),
    precision: dinero.getPrecision(),
  } as Money;
};

export const toMoneyAmount = (input: string) => {
  const toNumber = input.replace(/[$]/g, '') || "0";

  const toFloat = parseFloat(toNumber).toFixed(2);
  const result = parseInt(toFloat.replace(/[.]/g, ''));

  return result;
};

export const moneyToNumber = (input: Money): number => {
  const dinero = dineroFactory(input as DineroObject);
  return dinero.toUnit();
};

export const subtractMoneyValues = (
  value1: Pick<Money, "amount" | "currency" | "precision"> | null | undefined,
  value2: Pick<Money, "amount" | "currency" | "precision"> | null | undefined,
): Money | null => {

  const numberSubtractFrom = dineroFactory(value1 as DineroObject);
  const numberToSubtract = dineroFactory(value2 as DineroObject);
  const totalDinero = numberSubtractFrom.subtract(numberToSubtract);

  return {
    amount: totalDinero.getAmount(),
    currency: totalDinero.getCurrency(),
    precision: totalDinero.getPrecision(),
  } as Money;
};