import React from "react";
import { gql } from "@apollo/client";
import { VersionInfoFragment } from "../../../packages/settlement-graph";
import { Text, TextType } from "../../../packages/ui-kit";

interface VersionInfoProps {
  service: VersionInfoFragment
}

const VersionInfo = ({ service }: VersionInfoProps) => (
  <div>
    <Text type={TextType.bodyPrimaryStrong}
          className="paddingBottom">{service.serviceName.toUpperCase()}</Text>
    <div className="paddingLeft paddingBottom">
      <Text type={TextType.bodyPrimaryStrong}>BRANCH_NAME</Text>
      <Text className="paddingLeft">{service.branchName || "--"}</Text>
      <Text type={TextType.bodyPrimaryStrong}>BUILD_NUMBER</Text>
      <Text className="paddingLeft">{service.buildNumber || "--"}</Text>
      <Text type={TextType.bodyPrimaryStrong}>COMMIT_HASH</Text>
      <Text className="paddingLeft">{service.commitHash || "--"}</Text>
    </div>
  </div>
);

VersionInfo.fragments = {
  versionInfo: gql`
    fragment VersionInfo on VersionInfo {
      buildNumber
      commitHash
      serviceName
      branchName
    }
  `
};

export default VersionInfo;
