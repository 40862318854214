import React from "react";
import "./GhostTile.scss";

type GhostTileProps = {
  width?: string;
  height?: string;
  margin?: string;
  opacity?: string;
  flexGrow?: number;
};

const GhostTile = ({width, height, margin, opacity, flexGrow}: GhostTileProps) => {
  const style = {width, height, margin, opacity, flexGrow};
  return (<div className="ghost-tile" style={style} />);
};

export default GhostTile;
