import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { OKTA_SESSION_EXPIRED_REDIRECT_URL } from '../../packages/auth/constants/okta';

const INTERVAL = 40000;
export const AuthTracker = () => {
    const { oktaAuth } = useOktaAuth();
    const [expTimeState, setExpTimeState] = useState<number>((Date.now() / 1000) + (60 * 60 * 8));
    const time = (expTimeState > (Date.now() / 1000));
    const [timeRemaining, setTimeRemaining] = useState<boolean>(time);
    const [signedOut, setSignedOut] = useState<boolean>(false);

    const [timeRemainingSeconds, setTimeRemainingInSeconds] = useState<number>(expTimeState - (Date.now() / 1000));
    useEffect(
        () => {
            const timer = function () {
                if (window.sessionStorage.getItem("initialExpireState") !== undefined) {
                    const storageItem = window.sessionStorage.getItem("initialExpireState")?.toString();
                    if (storageItem) {
                        setExpTimeState(JSON.parse(storageItem).expTime);
                    }
                }
                else {
                    setExpTimeState((Date.now() / 1000) + (60 * 60 * 8));
                }

                if (!timeRemaining) {
                    if (!signedOut) {
                        console.log(`Sign out from AuthTracker at ${Date.now() / 1000}`);
                        try {
                            oktaAuth.revokeAccessToken();
                            oktaAuth.revokeRefreshToken();
                            oktaAuth.session.close().then(() => {
                                oktaAuth.tokenManager.clear();
                                window.location.reload();
                            });
                            setSignedOut(true);
                        }
                        catch (ex) {
                            console.log(ex);
                            window.location.replace(OKTA_SESSION_EXPIRED_REDIRECT_URL);
                        }
                    }

                    return;
                }
                else {
                    setTimeRemainingInSeconds(expTimeState - (Date.now() / 1000));
                    setTimeRemaining(
                        ((expTimeState - 40) > (Date.now() / 1000)));
                }
            };
            const id = setInterval(timer, INTERVAL);
            return () => clearInterval(id);
        },
        [expTimeState, oktaAuth, signedOut, timeRemaining, timeRemainingSeconds]
    );
    return null;
};