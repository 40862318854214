import { SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import Navigation from "./Navigation/Navigation";
import DebugModal from "./components/DebugModal";
import TimeoutModal from "./components/TimeoutModal/TimeoutModal";
import { Flag, useFeature } from "../packages/feature-flags";
import { OKTA_LOGIN_CALLBACK_URI, OKTA_SESSION_EXPIRED_REDIRECT_URL } from "../packages/auth/constants/okta";
import { useIdleTimer } from 'react-idle-timer';
import MaxSessionTimeout from './components/MaxSessionTimeout';
import { AuthTracker } from './Navigation/AuthTracker';
import ExpireState from './Navigation/ExpireState';


const skipLogin = process.env.REACT_APP_SKIP_LOGIN === "true";

// usually true if mock or local
const overrideRouteToMyArc = true;
const myArcUrl = process.env.REACT_APP_MYARC_URL || "https://myarc.arccorp.com";

interface RouteArgs {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Module: any;
}

const SettlementsPageLoader = React.lazy(() =>
  import(/* webpackChunkName: "Home", webpackPreload: true */ "../packages/settlements")
);

const NotFoundLoader = React.lazy(() =>
  import(/* webpackChunkName: "404Page" */ "./components/PageNotFound")
);

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {

  const [visible, setVisible] = useState<boolean>(false);

  const { authState } = useOktaAuth();
  //@ts-ignore
  const idleTimeoutDuration = window.Cypress ? 10000 : (1000 * 60 * 13);

  //@ts-ignore
  const showTimeoutModal = window.Cypress ? true : (authState?.isAuthenticated ?? false);
  const { reset } = useIdleTimer({
    timeout: idleTimeoutDuration,
    //don't want to show this modal when session is expired
    onIdle: () => handleShowTimeoutModal(showTimeoutModal),
    onAction: () => !visible ? reset() : null,
  });

  const handleShowTimeoutModal = (flag: boolean) => {
    setVisible(flag);
  };

  const handleOnAuthRequired = () => {
    if (window.sessionStorage.getItem("initialExpireState")) {
      window.location.replace(OKTA_SESSION_EXPIRED_REDIRECT_URL);
      window.sessionStorage.removeItem("initialExpireState");
    }
    else {
      window.location.replace("/");
    }
  };

  const IndexPageLoaderRoute = (
    <SecureRoute path="/" exact={true} render={props => <SettlementsPageLoader {...props} />} />
  );

  const routes = [
    {
      Module: SettlementsPageLoader,
      path: "/settlements"
    },
    {
      Module: MaxSessionTimeout,
      path: "/session-expired"
    },
    { path: "/*", Module: NotFoundLoader }
  ] as RouteArgs[];



  const renderPrivateRoute = ({ path, Module }: RouteArgs) => {

    // skipping login - used for development purposes ONLY
    if (skipLogin || path === "/session-expired") {
      return <Route
        key={path}
        path={path}
        render={props => <Module {...props} />}
      />;
    }
    else if ((authState?.isAuthenticated === true || overrideRouteToMyArc)) {
      // authenticated or while overriding MyARC (local and mock)
      if (!window.sessionStorage.getItem("initialExpireState") && authState?.accessToken?.expiresAt) {
        if (authState?.accessToken?.expiresAt > 0) {
          ExpireState.expTime = authState?.accessToken?.expiresAt;
          window.sessionStorage.setItem("initialExpireState", JSON.stringify(ExpireState));
        }
      }
      return (
        <SecureRoute
          onAuthRequired={handleOnAuthRequired}
          key={path}
          path={path}
          render={props => <Module {...props} />}
        />
      );
    } else {
      // not authenticated and not overriding MyARC
      window.location.replace(myArcUrl);
    }
  };

  const isDevToolsEnabled = useFeature(Flag.DEV_TOOLS);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navigation>
        <Switch>
          {IndexPageLoaderRoute}
          <Route path={OKTA_LOGIN_CALLBACK_URI} component={LoginCallback} />
          {routes.map(renderPrivateRoute)}
        </Switch>
      </Navigation>
      {isDevToolsEnabled ? <DebugModal /> : null}
      <TimeoutModal handleShowTimeoutModal={handleShowTimeoutModal} visible={visible} />
      <AuthTracker />
    </BrowserRouter>
  );
};

export default AppRouter;
