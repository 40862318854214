import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Query = {
  __typename?: 'Query';
  agency: Agency;
  agencies: PaginatedAgency;
  airline: Airline;
  airlines: Array<Airline>;
  searchedItems: OrderSearchResults;
  item?: Maybe<Item>;
  exportStatus?: Maybe<ExportStatus>;
  versions: Array<VersionInfo>;
  transaction?: Maybe<Transaction>;
  searchTransactions: SearchTransactionResponse;
};


export type QueryAgencyArgs = {
  effectiveOnDate: Scalars['String'];
  id: Scalars['String'];
};


export type QueryAgenciesArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryAirlineArgs = {
  effectiveOnDate: Scalars['String'];
  id: Scalars['String'];
};


export type QuerySearchedItemsArgs = {
  from?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  documentNumbers?: Maybe<Scalars['String']>;
  airlineNumCode?: Maybe<Scalars['String']>;
  arcNumber?: Maybe<Scalars['String']>;
  dateOfIssue?: Maybe<Scalars['String']>;
  orderDocumentTypes?: Maybe<Scalars['String']>;
  passengerLastName?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  recordLocator?: Maybe<Scalars['String']>;
  reportingPeriod?: Maybe<Scalars['String']>;
  settledDate?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};


export type QueryItemArgs = {
  id: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};


export type QueryExportStatusArgs = {
  executionId: Scalars['String'];
};


export type QueryTransactionArgs = {
  id: Scalars['String'];
};


export type QuerySearchTransactionsArgs = {
  from?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  documentNumbers?: Maybe<Scalars['String']>;
  airlineNumCode?: Maybe<Scalars['String']>;
  arcNumber?: Maybe<Scalars['String']>;
  dateOfIssue?: Maybe<Scalars['String']>;
  passengerLastName?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type Agency = {
  __typename?: 'Agency';
  agencyStatus: Scalars['String'];
  ticketingAuthority: Scalars['String'];
  arcPayDisplayName?: Maybe<Scalars['String']>;
  icdnExcluded?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  arcNumber: Scalars['String'];
  homeOfficeLocationNumber: Scalars['String'];
  legalName: Scalars['String'];
  dbaName: Scalars['String'];
  officeType: Scalars['String'];
  accreditationType: Scalars['String'];
};

export type PaginatedAgency = {
  __typename?: 'PaginatedAgency';
  data: Array<Agency>;
  cursor?: Maybe<Scalars['String']>;
};

export type Airline = {
  __typename?: 'Airline';
  designator: Scalars['String'];
  numCode: Scalars['String'];
  numCodeCheck?: Maybe<Scalars['Float']>;
  carrierType?: Maybe<AirlineEntityCarrierType>;
  participation: AirlineEntityParticipation;
  agencyAppointment?: Maybe<AirlineEntityAgencyAppointment>;
  catFreq?: Maybe<AirlineEntityCatFreq>;
  legalName: Scalars['String'];
  dbaName?: Maybe<Scalars['String']>;
  status?: Maybe<AirlineEntityStatus>;
  selfLink: Scalars['String'];
  lastUpdated: Scalars['String'];
  createdDate: Scalars['String'];
  lastModifiedBy: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
};

export enum AirlineEntityCarrierType {
  Scheduled = 'Scheduled',
  Charter = 'Charter',
  Railroad = 'Railroad',
  Other = 'Other'
}

export enum AirlineEntityParticipation {
  Full = 'Full',
  Associate = 'Associate',
  TransactionOnly = 'TransactionOnly'
}

export enum AirlineEntityAgencyAppointment {
  GeneralConcurrence = 'GeneralConcurrence',
  SpecificAppointment = 'SpecificAppointment'
}

export enum AirlineEntityCatFreq {
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export enum AirlineEntityStatus {
  ApplicationPending = 'ApplicationPending',
  ApplicationWithdrawn = 'ApplicationWithdrawn',
  Active = 'Active',
  ActiveNotProcessing = 'ActiveNotProcessing',
  FullyPartiallyInhibited = 'FullyPartiallyInhibited',
  Deleted = 'Deleted'
}

export type OrderSearchResults = {
  __typename?: 'OrderSearchResults';
  data: Array<Maybe<Item>>;
  total: Scalars['Float'];
};

export type Item = {
  __typename?: 'Item';
  itemId: Scalars['ID'];
  primaryDocumentNumber: Scalars['String'];
  passengerName?: Maybe<Scalars['String']>;
  certificateNumbers?: Maybe<Array<Scalars['String']>>;
  dateOfIssue?: Maybe<Scalars['String']>;
  settledDate?: Maybe<Scalars['String']>;
  refundSettledDate?: Maybe<Scalars['String']>;
  pnrReference?: Maybe<Scalars['String']>;
  fullDocumentNumber?: Maybe<Scalars['String']>;
  agency: Agency;
  airline?: Maybe<Airline>;
  authorized: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  void: Scalars['Boolean'];
  voidInfo?: Maybe<VoidInfo>;
  exchangeInfo?: Maybe<ExchangeInfo>;
  ticketDocumentInfo: TicketDocumentInfo;
  refunds?: Maybe<Array<RefundInfo>>;
  /** @deprecated Use netRemitTotal and refundNetRemitTotal */
  netRemits: Array<NetRemit>;
  netRemitTotal?: Maybe<Money>;
  refundNetRemitTotal?: Maybe<Money>;
  taxes: Array<Tax>;
  refundTaxes: Array<Tax>;
  adminPenaltyFee?: Maybe<Fee>;
  commission: Commission;
  commissions: Array<Commission>;
  refundCommissions: Array<Commission>;
  refundCommission?: Maybe<RefundCommission>;
  payments: Array<Payment>;
  paymentDisplay?: Maybe<Scalars['String']>;
  coupons?: Maybe<Array<Coupon>>;
  history: Array<ItemHistories>;
  prices: Prices;
  refundPrices: Prices;
  /** Exchange information for the item */
  exchange?: Maybe<Exchange>;
  passenger?: Maybe<Passenger>;
  transactionType: TransactionType;
  orderDocumentType: Scalars['String'];
  changelog?: Maybe<Array<Changelog>>;
  aadInfo?: Maybe<AadInfo>;
};

export type VoidInfo = {
  __typename?: 'VoidInfo';
  unmatchedVoidIndicator?: Maybe<Scalars['Boolean']>;
  issuedDate?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['String']>;
  eventSource?: Maybe<Scalars['String']>;
};

export type ExchangeInfo = {
  __typename?: 'ExchangeInfo';
  exchangeIssuedDate?: Maybe<Scalars['String']>;
  exchangeSettledDate?: Maybe<Scalars['String']>;
  /** @deprecated use value */
  exchangeAmount?: Maybe<Money>;
  value?: Maybe<Money>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Int'];
  precision: Scalars['Int'];
  currency: Scalars['String'];
  /** A human readable format of the money */
  display: Scalars['String'];
};

export type TicketDocumentInfo = {
  __typename?: 'TicketDocumentInfo';
  waiverCodeCertificate?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  orderDocumentType?: Maybe<Scalars['String']>;
  reasonForIssuanceDescription?: Maybe<Scalars['String']>;
  endorsementRestriction?: Maybe<Scalars['String']>;
  internationalSaleIndicator?: Maybe<Scalars['Boolean']>;
  fareCalculationModeIndicator?: Maybe<Scalars['String']>;
  tourCode?: Maybe<Scalars['String']>;
  reasonForIssuanceCode?: Maybe<Scalars['String']>;
  authorized?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Array<Scalars['String']>>;
  fareCalculationArea?: Maybe<Array<Scalars['String']>>;
  fareCalculationPricingIndicator?: Maybe<Scalars['String']>;
  fareCalculationPricingIndicatorDescription?: Maybe<Scalars['String']>;
  attributeGroup?: Maybe<Scalars['String']>;
  attributeSubGroup?: Maybe<Scalars['String']>;
  industryCarrierIndicator?: Maybe<Scalars['String']>;
  primaryDocumentNumber?: Maybe<Scalars['String']>;
  /** Used when DocumentType is AAD */
  summaryReferenceNumber?: Maybe<Scalars['String']>;
  /** Used when DocumentType is AAD */
  originalDocumentNumber?: Maybe<Scalars['String']>;
};

export type RefundInfo = {
  __typename?: 'RefundInfo';
  id: Scalars['String'];
  couponReferences: Array<Scalars['String']>;
  indicator: RefundInfoEntityIndicator;
  refundIssuedDate?: Maybe<Scalars['String']>;
  refundSettledDate?: Maybe<Scalars['String']>;
  refundedAmount?: Maybe<Money>;
  dueAmount?: Maybe<Money>;
  dateOfIssue: Scalars['String'];
  waiverCode?: Maybe<Scalars['String']>;
  eventSource?: Maybe<RefundInfoEntityEventSource>;
};

export enum RefundInfoEntityIndicator {
  P = 'P',
  F = 'F'
}

export enum RefundInfoEntityEventSource {
  Arc = 'Arc',
  SystemProvider = 'SystemProvider',
  Automated = 'Automated',
  Unknown = 'Unknown'
}

export type NetRemit = {
  __typename?: 'NetRemit';
  reference: Scalars['ID'];
  extractDate: Scalars['String'];
  reportPeriodEndDate: Scalars['String'];
  value?: Maybe<Money>;
  periodNumber: Scalars['Int'];
};

export type Tax = {
  __typename?: 'Tax';
  value: Money;
  code?: Maybe<Scalars['String']>;
  subCode?: Maybe<Scalars['String']>;
  refund?: Maybe<Scalars['Boolean']>;
};

export type Fee = {
  __typename?: 'Fee';
  value: Money;
  code?: Maybe<Scalars['String']>;
  subCode?: Maybe<Scalars['String']>;
  refund?: Maybe<Scalars['Boolean']>;
};

export type Commission = {
  __typename?: 'Commission';
  value: Money;
  rate?: Maybe<Scalars['Float']>;
  commissionType?: Maybe<CommissionEntityCommissionType>;
  refund?: Maybe<Scalars['Boolean']>;
};

/** Enum that represents the type of commission. */
export enum CommissionEntityCommissionType {
  Xlp = 'Xlp',
  New = 'New',
  Old = 'Old'
}

export type RefundCommission = {
  __typename?: 'RefundCommission';
  old?: Maybe<Commission>;
  adminPenalty?: Maybe<Commission>;
};

export type Payment = {
  __typename?: 'Payment';
  addressVerificationCode?: Maybe<Scalars['String']>;
  additionalTransactionInfo?: Maybe<Scalars['String']>;
  approvalCode?: Maybe<Scalars['String']>;
  authorizedAmount?: Maybe<Money>;
  creditPaymentType?: Maybe<Scalars['String']>;
  currencyType?: Maybe<Scalars['String']>;
  customerFileReference?: Maybe<Scalars['String']>;
  extendedPaymentCode?: Maybe<Scalars['String']>;
  formOfPaymentAccountNumber?: Maybe<Scalars['String']>;
  formOfPaymentAmount: Money;
  formOfPaymentType: PaymentEntityFormOfPaymentType;
  formOfPaymentSubType: Scalars['String'];
  formOfPaymentTransactionInfo?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  transactionTypeId?: Maybe<Scalars['String']>;
  exchangedDocumentReferences?: Maybe<Array<DocumentReferences>>;
  exchangeIssueDate?: Maybe<Scalars['String']>;
};

export enum PaymentEntityFormOfPaymentType {
  Ca = 'Ca',
  Cc = 'Cc',
  Ex = 'Ex',
  Rf = 'Rf',
  Tc = 'Tc'
}

export type DocumentReferences = {
  __typename?: 'DocumentReferences';
  coupons: Array<Scalars['String']>;
  documentNumber: Scalars['String'];
  indicator: DocumentReferencesIndicator;
};

export enum DocumentReferencesIndicator {
  F = 'F',
  P = 'P'
}

export type Coupon = {
  __typename?: 'Coupon';
  ticketDocumentNumber: Scalars['String'];
  ticketingDateOfIssue?: Maybe<Scalars['String']>;
  couponNumber: Scalars['Int'];
  couponValue?: Maybe<Scalars['String']>;
  fareBasis?: Maybe<Scalars['String']>;
  ticketDesignator?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  soldAirlineDesignator?: Maybe<Scalars['String']>;
  soldFlightDepartureDate?: Maybe<Scalars['String']>;
  soldFlightDepartureDateTime?: Maybe<Scalars['String']>;
  soldFlightDepartureTime?: Maybe<Scalars['String']>;
  soldOriginCityCode?: Maybe<Scalars['String']>;
  soldDestinationCityCode?: Maybe<Scalars['String']>;
  soldFlightNumber?: Maybe<Scalars['String']>;
  soldReservationBookingDesignator?: Maybe<Scalars['String']>;
  soldReservationStatusCode?: Maybe<Scalars['String']>;
  stopoverCode?: Maybe<Scalars['String']>;
  classOfService?: Maybe<Scalars['String']>;
  status?: Maybe<CouponStatus>;
  useIndicator?: Maybe<Scalars['String']>;
  reasonForIssuanceSubCode?: Maybe<Scalars['String']>;
  reasonForIssuanceSubCodeDescription?: Maybe<Scalars['String']>;
  value?: Maybe<Money>;
};

export enum CouponStatus {
  Refunded = 'Refunded',
  Exchanged = 'Exchanged',
  Arunk = 'Arunk'
}

export type ItemHistories = IssuedHistory | SettledHistory | RefundedHistory | ExchangedHistory | VoidedHistory | CancelledHistory;

export type IssuedHistory = ItemHistory & {
  __typename?: 'IssuedHistory';
  date: Scalars['String'];
  historyType: ItemStatusEntityCode;
  reason?: Maybe<Scalars['String']>;
  bookingAgent?: Maybe<Scalars['String']>;
  systemProvider?: Maybe<Scalars['String']>;
};

export type ItemHistory = {
  date: Scalars['String'];
  historyType: ItemStatusEntityCode;
  reason?: Maybe<Scalars['String']>;
};

export enum ItemStatusEntityCode {
  Issued = 'Issued',
  Settled = 'Settled',
  Refunded = 'Refunded',
  Exchanged = 'Exchanged',
  Voided = 'Voided',
  Cancelled = 'Cancelled'
}

export type SettledHistory = ItemHistory & {
  __typename?: 'SettledHistory';
  date: Scalars['String'];
  historyType: ItemStatusEntityCode;
  reason?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  settledOutOfPeriod?: Maybe<Scalars['Boolean']>;
};

export type RefundedHistory = ItemHistory & {
  __typename?: 'RefundedHistory';
  date: Scalars['String'];
  historyType: ItemStatusEntityCode;
  reason?: Maybe<Scalars['String']>;
  esacNumber?: Maybe<Scalars['String']>;
  issuingArcNumber?: Maybe<Scalars['String']>;
};

export type ExchangedHistory = ItemHistory & {
  __typename?: 'ExchangedHistory';
  date: Scalars['String'];
  historyType: ItemStatusEntityCode;
  reason?: Maybe<Scalars['String']>;
  esacNumber?: Maybe<Scalars['String']>;
  issuingArcNumber?: Maybe<Scalars['String']>;
};

export type VoidedHistory = ItemHistory & {
  __typename?: 'VoidedHistory';
  date: Scalars['String'];
  historyType: ItemStatusEntityCode;
  reason?: Maybe<Scalars['String']>;
  voidType: ItemStatusEntitySubCode;
  esacNumber?: Maybe<Scalars['String']>;
  esacSource?: Maybe<Scalars['String']>;
  issuingArcNumber?: Maybe<Scalars['String']>;
};

export enum ItemStatusEntitySubCode {
  Refund = 'Refund',
  Exchange = 'Exchange',
  Document = 'Document',
  Void = 'Void'
}

export type CancelledHistory = ItemHistory & {
  __typename?: 'CancelledHistory';
  date: Scalars['String'];
  historyType: ItemStatusEntityCode;
  reason?: Maybe<Scalars['String']>;
  esacNumber?: Maybe<Scalars['String']>;
  issuingArcNumber?: Maybe<Scalars['String']>;
};

export type Prices = {
  __typename?: 'Prices';
  baseFareAmount?: Maybe<Money>;
  equivalentFarePaid?: Maybe<Money>;
  ticketDocumentAmount?: Maybe<Money>;
  internationalFareAmount?: Maybe<Money>;
};

export type Exchange = {
  __typename?: 'Exchange';
  /** UUID for the exchange */
  id: Scalars['ID'];
  /** Flag that tell you if this exchange is a top level exchange */
  isRootExchange: Scalars['Boolean'];
  /** Parent exchange */
  parentExchange?: Maybe<Exchange>;
  /** Returns a list of taxes created as part of this exchange. */
  taxes: Array<Tax>;
  /** Returns a list of Fees created as part of this exchange. */
  fees: Array<Fee>;
  /** Total amount of value created from this exchange */
  value: Money;
  /** The amount paid for (+) or reimbursed (-) on top of the exchanged amount */
  netValue?: Maybe<Money>;
  /** Parent exchange ids for this exchange */
  parentIds: Array<Scalars['String']>;
  /** Returns a list of items returned as part of this exchange. */
  exchangedItems: Array<Item>;
  /** Returns a list of exchanged coupon numbers */
  exchangedCouponNumbers: Array<Scalars['String']>;
  /** Returns an indicator, P (partial) or F (full) exchange */
  exchangeIndicator: Scalars['String'];
  /** Returns a list of items created by the exchange. */
  resolvedItems: Array<Item>;
  /** Cost of new documents in an exchange */
  costOfNewDocuments?: Maybe<Money>;
  /** Cost of old (exchanged) documents in an exchange */
  costOfOldDocuments?: Maybe<Money>;
  /** Returns list of exchanges that share the same thread */
  thread: Array<Exchange>;
  exchangeIssuedDate?: Maybe<Scalars['String']>;
  exchangeSettledDate?: Maybe<Scalars['String']>;
  /**
   * List of commissions associated with the exchange
   * @deprecated Use commission instead
   */
  commissions?: Maybe<Array<Commission>>;
  /** Commissions on old, new and admin/penalty associated with the exchange */
  commission?: Maybe<ExchangeCommission>;
};

export type ExchangeCommission = {
  __typename?: 'ExchangeCommission';
  old?: Maybe<Commission>;
  adminPenalty?: Maybe<Commission>;
  new?: Maybe<Commission>;
};

export type Passenger = {
  __typename?: 'Passenger';
  customerIdentification?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  frequentFlyers?: Maybe<Array<FrequentFlyer>>;
};

export type FrequentFlyer = {
  __typename?: 'FrequentFlyer';
  airline?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  Refund = 'REFUND',
  AutomatedRefund = 'AUTOMATED_REFUND',
  Cancelled = 'CANCELLED',
  Void = 'VOID',
  Ticket = 'TICKET',
  Exchange = 'EXCHANGE',
  Emda = 'EMDA',
  Emds = 'EMDS',
  Mco = 'MCO',
  ArcPay = 'ARC_PAY',
  CreditMemo = 'CREDIT_MEMO',
  DebitMemo = 'DEBIT_MEMO',
  Aad = 'AAD',
  CommissionRecall = 'COMMISSION_RECALL',
  AirlineDirectTicket = 'AIRLINE_DIRECT_TICKET',
  Unknown = 'UNKNOWN'
}

export type Changelog = {
  __typename?: 'Changelog';
  changes: Array<ChangelogItem>;
  author?: Maybe<Scalars['String']>;
  eventLink?: Maybe<Scalars['String']>;
};

export type ChangelogItem = {
  __typename?: 'ChangelogItem';
  previousValue?: Maybe<Scalars['JSON']>;
  newValue?: Maybe<Scalars['JSON']>;
  property: Scalars['String'];
};


export type AadInfo = {
  __typename?: 'AadInfo';
  taxes: AadAdjustedAmount;
  commissionAmount: AadAdjustedAmount;
  commissionPercent: AadAdjustedNumber;
  documentTotal: AadAdjustedAmount;
  baseFare: AadAdjustedAmount;
  netRemit: AadAdjustedAmount;
  originalArcNumber?: Maybe<Scalars['String']>;
  originalDocumentNumber?: Maybe<Scalars['String']>;
  originalDocumentSequenceNumber?: Maybe<Scalars['String']>;
  originalTranNumber?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type AadAdjustedAmount = {
  __typename?: 'AadAdjustedAmount';
  listedAs: Money;
  needsToBe: Money;
};

export type AadAdjustedNumber = {
  __typename?: 'AadAdjustedNumber';
  listedAs?: Maybe<Scalars['Float']>;
  needsToBe?: Maybe<Scalars['Float']>;
};

export type ExportStatus = {
  __typename?: 'ExportStatus';
  executionId: Scalars['String'];
  status: ExportProgressStatus;
  downloadUrl?: Maybe<Scalars['String']>;
};

export enum ExportProgressStatus {
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  TimedOut = 'TIMED_OUT',
  Aborted = 'ABORTED'
}

export type VersionInfo = {
  __typename?: 'VersionInfo';
  /** the build number the service is using */
  buildNumber: Scalars['String'];
  /** the commit hash the service is using */
  commitHash: Scalars['String'];
  /** the name of the service */
  serviceName: Scalars['String'];
  /** the name of the branch the service is using */
  branchName: Scalars['String'];
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  type: NewEventType;
  transactionType: NewTransactionType;
  dateOfIssue: Scalars['String'];
  settledDate?: Maybe<Scalars['String']>;
  primaryDocumentNumber: Scalars['String'];
  esac?: Maybe<Scalars['String']>;
  esacSource?: Maybe<NewEsacSource>;
  referenceNumber?: Maybe<Scalars['String']>;
  issuingArcNumber?: Maybe<Scalars['String']>;
  source: NewSourceCode;
  eventSource: NewEventSourceType;
  carrierCode: Scalars['String'];
  arcNumber: Scalars['String'];
  settledOutOfPeriod?: Maybe<Scalars['Boolean']>;
  systemProvider?: Maybe<Scalars['String']>;
  oldDocuments?: Maybe<Array<Maybe<NewDocument>>>;
  newDocuments?: Maybe<Array<Maybe<NewDocument>>>;
  netRemit?: Maybe<NewNetRemit>;
  refundInfo?: Maybe<NewRefundInfo>;
  lockedDown: Scalars['Boolean'];
  voidInfo?: Maybe<NewVoidInfo>;
  voided?: Maybe<Scalars['Boolean']>;
};

export enum NewEventType {
  AppliedVoid = 'AppliedVoid',
  UnappliedVoid = 'UnappliedVoid',
  Transaction = 'Transaction'
}

export enum NewTransactionType {
  Canceled = 'Canceled',
  Sale = 'Sale',
  Refund = 'Refund',
  Exchange = 'Exchange'
}

export enum NewEsacSource {
  Arc = 'ARC',
  Airline = 'AIRLINE',
  SystemProvider = 'SYSTEM_PROVIDER'
}

export enum NewSourceCode {
  Archive = 'ARCHIVE',
  Sprf = 'SPRF',
  Transprep = 'TRANSPREP',
  Blockchain = 'BLOCKCHAIN',
  Unknown = 'UNKNOWN',
  Test = 'TEST'
}

export enum NewEventSourceType {
  Arc = 'Arc',
  SystemProvider = 'SystemProvider',
  Automated = 'Automated',
  Blockchain = 'Blockchain',
  Unknown = 'Unknown'
}

export type NewDocument = {
  __typename?: 'NewDocument';
  documentNumber: Scalars['String'];
  dateOfIssue: Scalars['String'];
  bookingAgentId?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankExchangeRate?: Maybe<Scalars['String']>;
  certificateNumbers?: Maybe<Array<Scalars['String']>>;
  pnrReference?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<Maybe<NewPayment>>>;
  prices?: Maybe<NewPrice>;
  taxes?: Maybe<Array<Maybe<NewTax>>>;
  fees?: Maybe<Array<Maybe<NewFee>>>;
  commissions?: Maybe<Array<Maybe<NewCommission>>>;
  aadInfo?: Maybe<NewAadInfo>;
  passenger?: Maybe<NewPassenger>;
  coupons?: Maybe<Array<Maybe<NewCoupon>>>;
  originalSaleDateOfIssue?: Maybe<Scalars['String']>;
  documentInfo?: Maybe<NewDocumentInfo>;
};

export type NewPayment = {
  __typename?: 'NewPayment';
  addressVerificationCode?: Maybe<Scalars['String']>;
  additionalTransactionInfo?: Maybe<Scalars['String']>;
  approvalCode?: Maybe<Scalars['String']>;
  authorizedAmount?: Maybe<Money>;
  creditPaymentType?: Maybe<Scalars['String']>;
  customerFileReference?: Maybe<Scalars['String']>;
  extendedPaymentCode?: Maybe<Scalars['String']>;
  formOfPaymentAccountNumber?: Maybe<Scalars['String']>;
  formOfPaymentAmount: Money;
  formOfPaymentType: NewFormOfPaymentType;
  formOfPaymentTransactionInfo?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  creditExpirationDate?: Maybe<Scalars['String']>;
  eCommerceIndicator?: Maybe<Scalars['String']>;
};

export enum NewFormOfPaymentType {
  CashPayment = 'CashPayment',
  CreditCardPayment = 'CreditCardPayment',
  Exchanged = 'Exchanged',
  CashRefund = 'CashRefund',
  CreditRefund = 'CreditRefund'
}

export type NewPrice = {
  __typename?: 'NewPrice';
  baseFareAmount?: Maybe<Money>;
  equivalentFarePaid?: Maybe<Money>;
  ticketDocumentAmount?: Maybe<Money>;
  internationalFareAmount?: Maybe<Money>;
};

export type NewTax = {
  __typename?: 'NewTax';
  value: Money;
  code?: Maybe<Scalars['String']>;
  subCode?: Maybe<Scalars['String']>;
};

export type NewFee = {
  __typename?: 'NewFee';
  value: Money;
  code?: Maybe<NewFeeCode>;
  subCode?: Maybe<Scalars['String']>;
};

export enum NewFeeCode {
  Xf = 'XF',
  Cp = 'CP',
  Mf = 'MF',
  Oa = 'OA',
  Ob = 'OB',
  Oc = 'OC',
  Od = 'OD',
  Yr = 'YR',
  Yq = 'YQ'
}

export type NewCommission = {
  __typename?: 'NewCommission';
  value: Money;
  rate?: Maybe<Scalars['Float']>;
  commissionType?: Maybe<NewCommissionType>;
};

export enum NewCommissionType {
  Xlp = 'XLP',
  New = 'NEW',
  Old = 'OLD'
}

export type NewAadInfo = {
  __typename?: 'NewAADInfo';
  taxes: NewAadAdjustedAmount;
  commissionAmount: NewAadAdjustedAmount;
  commissionPercent: NewAadAdjustedNumber;
  documentTotal: NewAadAdjustedAmount;
  baseFare: NewAadAdjustedAmount;
  netRemit: NewAadAdjustedAmount;
  originalArcNumber: Scalars['String'];
  originalDocumentNumber: Scalars['String'];
  originalDocumentSequenceNumber: Scalars['String'];
  originalTranNumber: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
};

export type NewAadAdjustedAmount = {
  __typename?: 'NewAADAdjustedAmount';
  listedAs: Money;
  needsToBe: Money;
};

export type NewAadAdjustedNumber = {
  __typename?: 'NewAADAdjustedNumber';
  listedAs?: Maybe<Scalars['Float']>;
  needsToBe?: Maybe<Scalars['Float']>;
};

export type NewPassenger = {
  __typename?: 'NewPassenger';
  customerIdentification?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  frequentFlyers?: Maybe<Array<NewFrequentFlyer>>;
};

export type NewFrequentFlyer = {
  __typename?: 'NewFrequentFlyer';
  airline?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
};

export type NewCoupon = {
  __typename?: 'NewCoupon';
  ticketDocumentNumber: Scalars['String'];
  couponNumber: Scalars['Float'];
  value?: Maybe<Money>;
  fareBasis?: Maybe<Scalars['String']>;
  additionalServicesAttributeGroup?: Maybe<Scalars['String']>;
  ticketDesignator?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  soldAirlineDesignator?: Maybe<Scalars['String']>;
  soldFlightDepartureDate?: Maybe<Scalars['String']>;
  soldFlightDepartureTime?: Maybe<Scalars['String']>;
  soldOriginCityCode?: Maybe<Scalars['String']>;
  soldDestinationCityCode?: Maybe<Scalars['String']>;
  soldFlightNumber?: Maybe<Scalars['String']>;
  soldReservationBookingDesignator?: Maybe<Scalars['String']>;
  soldReservationStatusCode?: Maybe<Scalars['String']>;
  stopoverCode?: Maybe<Scalars['String']>;
  classOfService?: Maybe<Scalars['String']>;
  useIndicator?: Maybe<Scalars['String']>;
  conjunctionCompanionTicketIndicator?: Maybe<Scalars['String']>;
  reasonForIssuanceSubCode?: Maybe<Scalars['String']>;
  reasonForIssuanceSubCodeDescription?: Maybe<Scalars['String']>;
};

export type NewDocumentInfo = {
  __typename?: 'NewDocumentInfo';
  waiverCodeCertificate?: Maybe<Scalars['String']>;
  documentType?: Maybe<NewOrderDocumentType>;
  tiesDocumentType?: Maybe<NewTiesDocumentType>;
  reasonForIssuanceDescription?: Maybe<Scalars['String']>;
  reasonForIssuanceCode?: Maybe<Scalars['String']>;
  authorized?: Maybe<Scalars['Boolean']>;
  fareCalculationArea?: Maybe<Array<Scalars['String']>>;
  internationalSaleIndicator?: Maybe<Scalars['Boolean']>;
  fareCalculationModeIndicator?: Maybe<Scalars['String']>;
  tourCode?: Maybe<Scalars['String']>;
  fareCalculationPricingIndicator?: Maybe<Scalars['String']>;
  fareCalculationPricingIndicatorDescription?: Maybe<Scalars['String']>;
  attributeGroup?: Maybe<Scalars['String']>;
  attributeSubGroup?: Maybe<Scalars['String']>;
  industryCarrierIndicator?: Maybe<Scalars['String']>;
  endorsementRestriction?: Maybe<Scalars['String']>;
  /** Used when DocumentType is AAD */
  summaryReferenceNumber?: Maybe<Scalars['String']>;
  /** Used when DocumentType is AAD */
  originalDocumentNumber?: Maybe<Scalars['String']>;
  isInclusiveTour?: Maybe<Scalars['Boolean']>;
  isBulkTicket?: Maybe<Scalars['Boolean']>;
  ticketingModeIndicator?: Maybe<Scalars['String']>;
  inConnectionWith?: Maybe<NewInConnectionWith>;
};

export enum NewOrderDocumentType {
  Ndc = 'NDC',
  Emda = 'EMDA',
  Emds = 'EMDS',
  Mco = 'MCO',
  Crm = 'CRM',
  Dbm = 'DBM',
  Rcc = 'RCC',
  Aad = 'AAD',
  Pay = 'PAY',
  Tkt = 'TKT',
  Ntr = 'NTR'
}

export enum NewTiesDocumentType {
  Adms = 'ADMS',
  Acms = 'ACMS',
  Emda = 'EMDA',
  Emds = 'EMDS',
  Md50 = 'MD50',
  Nont = 'NONT',
  Rcsm = 'RCSM',
  Taad = 'TAAD',
  Tasf = 'TASF',
  Tktt = 'TKTT',
  Cann = 'CANN',
  Canx = 'CANX',
  Canu = 'CANU',
  Rfnd = 'RFND'
}

export type NewInConnectionWith = {
  __typename?: 'NewInConnectionWith';
  checkDigit?: Maybe<Scalars['String']>;
  couponNumbers?: Maybe<Array<Scalars['String']>>;
  dateOfIssue?: Maybe<Scalars['String']>;
  documentNumber: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
};

export type NewNetRemit = {
  __typename?: 'NewNetRemit';
  extractDate: Scalars['String'];
  reportPeriodEndDate: Scalars['String'];
  value: Money;
  periodNumber?: Maybe<Scalars['Float']>;
};

export type NewRefundInfo = {
  __typename?: 'NewRefundInfo';
  couponReferences: Array<Scalars['String']>;
  indicator?: Maybe<NewRefundInfoIndicator>;
  endorsementRestriction?: Maybe<Scalars['String']>;
  refundedAmount: Money;
  dueAmount: Money;
  waiverCode?: Maybe<Scalars['String']>;
  unmatchedRefundIndicator?: Maybe<Scalars['Boolean']>;
};

export enum NewRefundInfoIndicator {
  P = 'P',
  F = 'F'
}

export type NewVoidInfo = {
  __typename?: 'NewVoidInfo';
  wavierCode?: Maybe<Scalars['String']>;
  unmatchedVoidIndicator?: Maybe<Scalars['Boolean']>;
  unappliedVoidIndicator?: Maybe<Scalars['Boolean']>;
  issuedDate?: Maybe<Scalars['String']>;
  voidDate: Scalars['String'];
  originalTransactionType?: Maybe<Scalars['String']>;
};

export type SearchTransactionResponse = {
  __typename?: 'SearchTransactionResponse';
  data: Array<Maybe<Transaction>>;
  total: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createExportMutation: ExportStatus;
};


export type MutationCreateExportMutationArgs = {
  createExportRequest: CreateExportRequest;
};

export type CreateExportRequest = {
  searchArgs: SearchOrderArgs;
  exportOptions: ExportOptions;
  fileType: ExportFileType;
  fileName: Scalars['String'];
};

export type SearchOrderArgs = {
  from?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  documentNumbers?: Maybe<Scalars['String']>;
  airlineNumCode?: Maybe<Scalars['String']>;
  arcNumber?: Maybe<Scalars['String']>;
  dateOfIssue?: Maybe<Scalars['String']>;
  orderDocumentTypes?: Maybe<Scalars['String']>;
  passengerLastName?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  recordLocator?: Maybe<Scalars['String']>;
  reportingPeriod?: Maybe<Scalars['String']>;
  settledDate?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};

export type ExportOptions = {
  showCommissions: Scalars['Boolean'];
  showPassengerName: Scalars['Boolean'];
  showRelatedDocs: Scalars['Boolean'];
};

export enum ExportFileType {
  Csv = 'CSV',
  Pdf = 'PDF'
}

export type CreateExportMutationVariables = Exact<{
  arcNumber?: Maybe<Scalars['String']>;
  airlineNumCode?: Maybe<Scalars['String']>;
  dateOfIssue?: Maybe<Scalars['String']>;
  documentNumbers?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  orderDocumentTypes?: Maybe<Scalars['String']>;
  passengerLastName?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  recordLocator?: Maybe<Scalars['String']>;
  reportingPeriod?: Maybe<Scalars['String']>;
  settledDate?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  fileType: ExportFileType;
  showCommissions: Scalars['Boolean'];
  showPassengerName: Scalars['Boolean'];
  showRelatedDocs: Scalars['Boolean'];
  fileName: Scalars['String'];
}>;


export type CreateExportMutation = (
  { __typename?: 'Mutation' }
  & { createExportMutation: (
    { __typename?: 'ExportStatus' }
    & Pick<ExportStatus, 'executionId' | 'status' | 'downloadUrl'>
  ) }
);

export type ExportStatusQueryVariables = Exact<{
  executionId: Scalars['String'];
}>;


export type ExportStatusQuery = (
  { __typename?: 'Query' }
  & { exportStatus?: Maybe<(
    { __typename?: 'ExportStatus' }
    & Pick<ExportStatus, 'executionId' | 'status' | 'downloadUrl'>
  )> }
);

export type AgencySearchInputQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
}>;


export type AgencySearchInputQuery = (
  { __typename?: 'Query' }
  & { agencies: (
    { __typename?: 'PaginatedAgency' }
    & Pick<PaginatedAgency, 'cursor'>
    & { data: Array<(
      { __typename?: 'Agency' }
      & Pick<Agency, 'id' | 'arcNumber' | 'dbaName'>
    )> }
  ) }
);

export type AirlineSearchInputQueryVariables = Exact<{ [key: string]: never; }>;


export type AirlineSearchInputQuery = (
  { __typename?: 'Query' }
  & { airlines: Array<(
    { __typename?: 'Airline' }
    & Pick<Airline, 'id' | 'numCode' | 'dbaName'>
  )> }
);

export type ExchangeThreadExchangeFragment = (
  { __typename?: 'Exchange' }
  & Pick<Exchange, 'id' | 'exchangedCouponNumbers' | 'exchangeIndicator' | 'isRootExchange'>
  & { parentExchange?: Maybe<(
    { __typename?: 'Exchange' }
    & Pick<Exchange, 'id'>
  )>, taxes: Array<(
    { __typename?: 'Tax' }
    & Pick<Tax, 'code' | 'subCode'>
    & { value: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
    ) }
  )>, netValue?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'precision' | 'currency'>
  )>, exchangedItems: Array<(
    { __typename?: 'Item' }
    & ResultsTableItemFragment
  )>, resolvedItems: Array<(
    { __typename?: 'Item' }
    & ResultsTableItemFragment
  )> }
);

export type ResultsTableItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'fullDocumentNumber' | 'primaryDocumentNumber' | 'itemId' | 'passengerName' | 'dateOfIssue' | 'settledDate' | 'refundSettledDate' | 'transactionType' | 'void' | 'paymentDisplay'>
  & { voidInfo?: Maybe<(
    { __typename?: 'VoidInfo' }
    & Pick<VoidInfo, 'issuedDate' | 'voidDate' | 'eventSource'>
  )>, exchangeInfo?: Maybe<(
    { __typename?: 'ExchangeInfo' }
    & Pick<ExchangeInfo, 'exchangeIssuedDate' | 'exchangeSettledDate'>
    & { value?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )> }
  )>, exchange?: Maybe<(
    { __typename?: 'Exchange' }
    & { thread: Array<(
      { __typename?: 'Exchange' }
      & Pick<Exchange, 'id' | 'isRootExchange'>
    )> }
  )>, agency: (
    { __typename?: 'Agency' }
    & Pick<Agency, 'arcNumber'>
  ), airline?: Maybe<(
    { __typename?: 'Airline' }
    & Pick<Airline, 'numCode'>
  )>, payments: Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'formOfPaymentAccountNumber' | 'formOfPaymentType' | 'creditPaymentType'>
    & { exchangedDocumentReferences?: Maybe<Array<(
      { __typename?: 'DocumentReferences' }
      & Pick<DocumentReferences, 'coupons' | 'documentNumber' | 'indicator'>
    )>> }
  )>, refunds?: Maybe<Array<(
    { __typename?: 'RefundInfo' }
    & Pick<RefundInfo, 'couponReferences' | 'indicator' | 'refundIssuedDate' | 'refundSettledDate' | 'eventSource'>
    & { dueAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, refundedAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )> }
  )>>, netRemitTotal?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, coupons?: Maybe<Array<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'ticketDocumentNumber' | 'ticketingDateOfIssue' | 'couponNumber' | 'status' | 'soldAirlineDesignator'>
    & { value?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )> }
  )>>, prices: (
    { __typename?: 'Prices' }
    & { ticketDocumentAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'precision' | 'currency'>
    )> }
  ), ticketDocumentInfo: (
    { __typename?: 'TicketDocumentInfo' }
    & Pick<TicketDocumentInfo, 'documentType' | 'orderDocumentType'>
  ), history: Array<(
    { __typename: 'IssuedHistory' }
    & Pick<IssuedHistory, 'historyType' | 'date' | 'reason' | 'bookingAgent'>
  ) | (
    { __typename: 'SettledHistory' }
    & Pick<SettledHistory, 'historyType' | 'date' | 'reason' | 'referenceNumber'>
  ) | (
    { __typename: 'RefundedHistory' }
    & Pick<RefundedHistory, 'historyType' | 'date' | 'reason' | 'esacNumber' | 'issuingArcNumber'>
  ) | (
    { __typename: 'ExchangedHistory' }
    & Pick<ExchangedHistory, 'historyType' | 'date' | 'reason' | 'esacNumber' | 'issuingArcNumber'>
  ) | (
    { __typename: 'VoidedHistory' }
    & Pick<VoidedHistory, 'historyType' | 'date' | 'reason' | 'esacNumber' | 'esacSource' | 'issuingArcNumber' | 'voidType'>
  ) | (
    { __typename: 'CancelledHistory' }
    & Pick<CancelledHistory, 'historyType' | 'date' | 'reason' | 'esacNumber' | 'issuingArcNumber'>
  )> }
);

export type SearchItemFragment = (
  { __typename?: 'Item' }
  & { exchange?: Maybe<(
    { __typename?: 'Exchange' }
    & { thread: Array<(
      { __typename?: 'Exchange' }
      & ExchangeThreadExchangeFragment
    )> }
  )> }
  & ResultsTableItemFragment
);

export type DocumentSearchQueryVariables = Exact<{
  arcNumber?: Maybe<Scalars['String']>;
  airlineNumCode?: Maybe<Scalars['String']>;
  dateOfIssue?: Maybe<Scalars['String']>;
  documentNumbers?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  orderDocumentTypes?: Maybe<Scalars['String']>;
  passengerLastName?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  recordLocator?: Maybe<Scalars['String']>;
  reportingPeriod?: Maybe<Scalars['String']>;
  settledDate?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
}>;


export type DocumentSearchQuery = (
  { __typename?: 'Query' }
  & { searchedItems: (
    { __typename?: 'OrderSearchResults' }
    & Pick<OrderSearchResults, 'total'>
    & { data: Array<Maybe<(
      { __typename?: 'Item' }
      & SearchItemFragment
    )>> }
  ) }
);

export type TransactionDetailQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TransactionDetailQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Item' }
    & ItineraryItemFragment
    & TicketCardItemFragment
    & FinancialInformationItemFragment
    & ItemHistoryItemFragment
  )>, initialItem?: Maybe<(
    { __typename?: 'Item' }
    & ItineraryItemFragment
    & TicketCardItemFragment
    & FinancialInformationItemFragment
    & ItemHistoryItemFragment
  )> }
);

export type TransactionDetailRelatedDocsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TransactionDetailRelatedDocsQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Item' }
    & RelatedDocumentsItemFragment
  )> }
);

export type FinancialInformationItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'primaryDocumentNumber' | 'fullDocumentNumber' | 'transactionType'>
  & { refunds?: Maybe<Array<(
    { __typename?: 'RefundInfo' }
    & Pick<RefundInfo, 'dateOfIssue' | 'indicator' | 'couponReferences'>
  )>>, exchange?: Maybe<(
    { __typename?: 'Exchange' }
    & { thread: Array<(
      { __typename?: 'Exchange' }
      & Pick<Exchange, 'id' | 'exchangeIssuedDate'>
      & { exchangedItems: Array<(
        { __typename?: 'Item' }
        & Pick<Item, 'primaryDocumentNumber'>
      )>, resolvedItems: Array<(
        { __typename?: 'Item' }
        & Pick<Item, 'primaryDocumentNumber'>
      )> }
      & FinancialInformationExchangeExchangeFragment
    )> }
  )> }
  & FinancialInformationRefundItemFragment
  & FinancialInformationArcPayItemFragment
  & FinancialInformationCommissionRecallItemFragment
  & FinancialInformationCreditDebitMemoItemFragment
  & FinancialInformationAgentDeductionItemFragment
  & FinancialInformationTicketItemFragment
);

export type FinancialInformationAgentDeductionItemFragment = (
  { __typename?: 'Item' }
  & { aadInfo?: Maybe<(
    { __typename?: 'AadInfo' }
    & { taxes: (
      { __typename?: 'AadAdjustedAmount' }
      & { listedAs: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ), needsToBe: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ) }
    ), commissionAmount: (
      { __typename?: 'AadAdjustedAmount' }
      & { listedAs: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ), needsToBe: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ) }
    ), commissionPercent: (
      { __typename?: 'AadAdjustedNumber' }
      & Pick<AadAdjustedNumber, 'listedAs' | 'needsToBe'>
    ), documentTotal: (
      { __typename?: 'AadAdjustedAmount' }
      & { listedAs: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ), needsToBe: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ) }
    ), baseFare: (
      { __typename?: 'AadAdjustedAmount' }
      & { listedAs: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ), needsToBe: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ) }
    ), netRemit: (
      { __typename?: 'AadAdjustedAmount' }
      & { listedAs: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ), needsToBe: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ) }
    ) }
  )> }
  & CommissionInformationItemFragment
);

export type FinancialInformationArcPayItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'certificateNumbers' | 'dateOfIssue' | 'settledDate' | 'pnrReference' | 'passengerName' | 'fullDocumentNumber'>
  & { airline?: Maybe<(
    { __typename?: 'Airline' }
    & Pick<Airline, 'numCode' | 'designator' | 'dbaName'>
  )>, agency: (
    { __typename?: 'Agency' }
    & Pick<Agency, 'arcNumber'>
  ), passenger?: Maybe<(
    { __typename?: 'Passenger' }
    & { frequentFlyers?: Maybe<Array<(
      { __typename?: 'FrequentFlyer' }
      & Pick<FrequentFlyer, 'referenceNumber'>
    )>> }
  )>, history: Array<(
    { __typename: 'IssuedHistory' }
    & Pick<IssuedHistory, 'historyType' | 'date' | 'reason' | 'bookingAgent'>
  ) | (
    { __typename: 'SettledHistory' }
    & Pick<SettledHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'RefundedHistory' }
    & Pick<RefundedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'ExchangedHistory' }
    & Pick<ExchangedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'VoidedHistory' }
    & Pick<VoidedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'CancelledHistory' }
    & Pick<CancelledHistory, 'historyType' | 'date' | 'reason'>
  )>, prices: (
    { __typename?: 'Prices' }
    & { ticketDocumentAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'display'>
    )>, baseFareAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'display'>
    )> }
  ), payments: Array<(
    { __typename?: 'Payment' }
    & { formOfPaymentAmount: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision' | 'display'>
    ) }
  )> }
  & FormOfPaymentInformationItemFragment
  & CommissionInformationItemFragment
);

export type FinancialInformationCommissionRecallItemFragment = (
  { __typename?: 'Item' }
  & FormOfPaymentInformationItemFragment
);

export type FinancialInformationCreditDebitMemoItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'transactionType'>
  & FormOfPaymentInformationItemFragment
);

export type CostOfExchangedDocumentsExchangeFragment = (
  { __typename?: 'Exchange' }
  & { taxes: Array<(
    { __typename?: 'Tax' }
    & TaxesInformationTaxFragment
  )>, costOfOldDocuments?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, exchangedItems: Array<(
    { __typename?: 'Item' }
    & Pick<Item, 'fullDocumentNumber'>
    & { prices: (
      { __typename?: 'Prices' }
      & { baseFareAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )>, ticketDocumentAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )> }
    ) }
  )> }
);

export type CostOfNewDocumentsExchangeFragment = (
  { __typename?: 'Exchange' }
  & { taxes: Array<(
    { __typename?: 'Tax' }
    & TaxesInformationTaxFragment
  )>, costOfNewDocuments?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, resolvedItems: Array<(
    { __typename?: 'Item' }
    & Pick<Item, 'fullDocumentNumber'>
    & { prices: (
      { __typename?: 'Prices' }
      & { baseFareAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )>, ticketDocumentAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )> }
    ), taxes: Array<(
      { __typename?: 'Tax' }
      & Pick<Tax, 'code' | 'subCode'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
      ) }
    )> }
  )> }
);

export type FinancialInformationExchangeExchangeFragment = (
  { __typename?: 'Exchange' }
  & Pick<Exchange, 'exchangedCouponNumbers' | 'exchangeIndicator'>
  & { netValue?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'precision' | 'currency'>
  )>, resolvedItems: Array<(
    { __typename?: 'Item' }
    & Pick<Item, 'transactionType' | 'paymentDisplay'>
    & { ticketDocumentInfo: (
      { __typename?: 'TicketDocumentInfo' }
      & Pick<TicketDocumentInfo, 'waiverCodeCertificate'>
    ), adminPenaltyFee?: Maybe<(
      { __typename?: 'Fee' }
      & Pick<Fee, 'code' | 'subCode'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      ) }
    )>, payments: Array<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'creditPaymentType' | 'approvalCode' | 'formOfPaymentType' | 'formOfPaymentAccountNumber'>
    )>, netRemitTotal?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )> }
  )>, commission?: Maybe<(
    { __typename?: 'ExchangeCommission' }
    & { old?: Maybe<(
      { __typename?: 'Commission' }
      & Pick<Commission, 'commissionType'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'display' | 'amount' | 'currency' | 'precision'>
      ) }
    )>, new?: Maybe<(
      { __typename?: 'Commission' }
      & Pick<Commission, 'commissionType'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'display' | 'amount' | 'currency' | 'precision'>
      ) }
    )>, adminPenalty?: Maybe<(
      { __typename?: 'Commission' }
      & Pick<Commission, 'commissionType'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'display' | 'amount' | 'currency' | 'precision'>
      ) }
    )> }
  )> }
  & CostOfExchangedDocumentsExchangeFragment
  & CostOfNewDocumentsExchangeFragment
);

export type FinancialInformationRefundItemFragment = (
  { __typename?: 'Item' }
  & { refundNetRemitTotal?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, refundPrices: (
    { __typename?: 'Prices' }
    & { baseFareAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'display'>
    )> }
  ), refundCommission?: Maybe<(
    { __typename?: 'RefundCommission' }
    & { old?: Maybe<(
      { __typename?: 'Commission' }
      & Pick<Commission, 'commissionType' | 'refund'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'display' | 'precision' | 'currency'>
      ) }
    )>, adminPenalty?: Maybe<(
      { __typename?: 'Commission' }
      & Pick<Commission, 'commissionType' | 'refund'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'display' | 'precision' | 'currency'>
      ) }
    )> }
  )>, refundTaxes: Array<(
    { __typename?: 'Tax' }
    & TaxesInformationTaxFragment
  )>, adminPenaltyFee?: Maybe<(
    { __typename?: 'Fee' }
    & Pick<Fee, 'code' | 'subCode'>
    & { value: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ) }
  )>, refunds?: Maybe<Array<(
    { __typename?: 'RefundInfo' }
    & Pick<RefundInfo, 'waiverCode' | 'eventSource'>
    & { dueAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'display'>
    )>, refundedAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'display'>
    )> }
  )>> }
  & FormOfPaymentInformationItemFragment
);

export type FinancialInformationTicketItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'certificateNumbers' | 'dateOfIssue' | 'settledDate' | 'pnrReference' | 'passengerName' | 'fullDocumentNumber'>
  & { ticketDocumentInfo: (
    { __typename?: 'TicketDocumentInfo' }
    & Pick<TicketDocumentInfo, 'tourCode' | 'fareCalculationArea' | 'waiverCodeCertificate' | 'internationalSaleIndicator' | 'fareCalculationPricingIndicator' | 'fareCalculationModeIndicator' | 'endorsementRestriction'>
  ), airline?: Maybe<(
    { __typename?: 'Airline' }
    & Pick<Airline, 'numCode' | 'designator' | 'dbaName'>
  )>, agency: (
    { __typename?: 'Agency' }
    & Pick<Agency, 'arcNumber'>
  ), passenger?: Maybe<(
    { __typename?: 'Passenger' }
    & { frequentFlyers?: Maybe<Array<(
      { __typename?: 'FrequentFlyer' }
      & Pick<FrequentFlyer, 'referenceNumber'>
    )>> }
  )>, history: Array<(
    { __typename: 'IssuedHistory' }
    & Pick<IssuedHistory, 'historyType' | 'date' | 'reason' | 'bookingAgent'>
  ) | (
    { __typename: 'SettledHistory' }
    & Pick<SettledHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'RefundedHistory' }
    & Pick<RefundedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'ExchangedHistory' }
    & Pick<ExchangedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'VoidedHistory' }
    & Pick<VoidedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'CancelledHistory' }
    & Pick<CancelledHistory, 'historyType' | 'date' | 'reason'>
  )>, taxes: Array<(
    { __typename?: 'Tax' }
    & TaxesInformationTaxFragment
  )>, prices: (
    { __typename?: 'Prices' }
    & { ticketDocumentAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'display'>
    )>, baseFareAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'display'>
    )> }
  ), netRemitTotal?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )> }
  & FormOfPaymentInformationItemFragment
  & CommissionInformationItemFragment
);

export type CommissionInformationItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'transactionType'>
  & { aadInfo?: Maybe<(
    { __typename?: 'AadInfo' }
    & { commissionPercent: (
      { __typename?: 'AadAdjustedNumber' }
      & Pick<AadAdjustedNumber, 'listedAs' | 'needsToBe'>
    ), commissionAmount: (
      { __typename?: 'AadAdjustedAmount' }
      & { listedAs: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      ), needsToBe: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      ) }
    ) }
  )>, prices: (
    { __typename?: 'Prices' }
    & { baseFareAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'precision' | 'currency'>
    )> }
  ), commission: (
    { __typename?: 'Commission' }
    & Pick<Commission, 'rate' | 'refund'>
    & { value: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'precision' | 'currency'>
    ) }
  ) }
);

export type FormOfPaymentInformationItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'transactionType' | 'paymentDisplay'>
  & { payments: Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'creditPaymentType' | 'approvalCode' | 'formOfPaymentType' | 'formOfPaymentAccountNumber'>
  )>, netRemitTotal?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )> }
);

export type PfcInformationTaxFragment = (
  { __typename?: 'Tax' }
  & Pick<Tax, 'code' | 'subCode' | 'refund'>
  & { value: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
  ) }
);

export type TaxesInformationTaxFragment = (
  { __typename?: 'Tax' }
  & Pick<Tax, 'code' | 'subCode' | 'refund'>
  & { value: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'precision' | 'currency' | 'display'>
  ) }
);

type HistoryRowHistory_IssuedHistory_Fragment = (
  { __typename: 'IssuedHistory' }
  & Pick<IssuedHistory, 'historyType' | 'date' | 'reason' | 'bookingAgent' | 'systemProvider'>
);

type HistoryRowHistory_SettledHistory_Fragment = (
  { __typename: 'SettledHistory' }
  & Pick<SettledHistory, 'historyType' | 'date' | 'reason' | 'referenceNumber' | 'settledOutOfPeriod'>
);

type HistoryRowHistory_RefundedHistory_Fragment = (
  { __typename: 'RefundedHistory' }
  & Pick<RefundedHistory, 'historyType' | 'date' | 'reason' | 'esacNumber' | 'issuingArcNumber'>
);

type HistoryRowHistory_ExchangedHistory_Fragment = (
  { __typename: 'ExchangedHistory' }
  & Pick<ExchangedHistory, 'historyType' | 'date' | 'reason' | 'esacNumber' | 'issuingArcNumber'>
);

type HistoryRowHistory_VoidedHistory_Fragment = (
  { __typename: 'VoidedHistory' }
  & Pick<VoidedHistory, 'historyType' | 'date' | 'reason' | 'esacSource' | 'esacNumber' | 'issuingArcNumber' | 'voidType'>
);

type HistoryRowHistory_CancelledHistory_Fragment = (
  { __typename: 'CancelledHistory' }
  & Pick<CancelledHistory, 'historyType' | 'date' | 'reason' | 'esacNumber' | 'issuingArcNumber'>
);

export type HistoryRowHistoryFragment = HistoryRowHistory_IssuedHistory_Fragment | HistoryRowHistory_SettledHistory_Fragment | HistoryRowHistory_RefundedHistory_Fragment | HistoryRowHistory_ExchangedHistory_Fragment | HistoryRowHistory_VoidedHistory_Fragment | HistoryRowHistory_CancelledHistory_Fragment;

export type ItemHistoryItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'transactionType'>
  & { history: Array<(
    { __typename?: 'IssuedHistory' }
    & HistoryRowHistory_IssuedHistory_Fragment
  ) | (
    { __typename?: 'SettledHistory' }
    & HistoryRowHistory_SettledHistory_Fragment
  ) | (
    { __typename?: 'RefundedHistory' }
    & HistoryRowHistory_RefundedHistory_Fragment
  ) | (
    { __typename?: 'ExchangedHistory' }
    & HistoryRowHistory_ExchangedHistory_Fragment
  ) | (
    { __typename?: 'VoidedHistory' }
    & HistoryRowHistory_VoidedHistory_Fragment
  ) | (
    { __typename?: 'CancelledHistory' }
    & HistoryRowHistory_CancelledHistory_Fragment
  )> }
);

export type CouponCouponFragment = (
  { __typename?: 'Coupon' }
  & Pick<Coupon, 'stopoverCode' | 'couponNumber' | 'soldFlightNumber' | 'soldAirlineDesignator' | 'soldOriginCityCode' | 'soldDestinationCityCode' | 'soldFlightDepartureDate' | 'soldFlightDepartureDateTime' | 'soldFlightDepartureTime' | 'soldReservationBookingDesignator' | 'fareBasis' | 'ticketDesignator' | 'status' | 'classOfService'>
);

export type ItineraryItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'void'>
  & { coupons?: Maybe<Array<(
    { __typename?: 'Coupon' }
    & CouponCouponFragment
  )>>, ticketDocumentInfo: (
    { __typename?: 'TicketDocumentInfo' }
    & Pick<TicketDocumentInfo, 'endorsementRestriction' | 'orderDocumentType'>
  ) }
);

export type RelatedDocumentsItemFragment = (
  { __typename?: 'Item' }
  & { exchange?: Maybe<(
    { __typename?: 'Exchange' }
    & { thread: Array<(
      { __typename?: 'Exchange' }
      & ExchangeThreadExchangeFragment
    )> }
  )> }
);

export type TicketCardItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'fullDocumentNumber' | 'primaryDocumentNumber' | 'passengerName' | 'dateOfIssue' | 'settledDate' | 'refundSettledDate' | 'pnrReference' | 'void' | 'transactionType'>
  & { voidInfo?: Maybe<(
    { __typename?: 'VoidInfo' }
    & Pick<VoidInfo, 'issuedDate' | 'voidDate' | 'eventSource'>
  )>, exchangeInfo?: Maybe<(
    { __typename?: 'ExchangeInfo' }
    & Pick<ExchangeInfo, 'exchangeIssuedDate' | 'exchangeSettledDate'>
  )>, agency: (
    { __typename?: 'Agency' }
    & Pick<Agency, 'arcNumber' | 'dbaName'>
  ), airline?: Maybe<(
    { __typename?: 'Airline' }
    & Pick<Airline, 'numCode' | 'designator' | 'dbaName'>
  )>, payments: Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'formOfPaymentType'>
  )>, refunds?: Maybe<Array<(
    { __typename?: 'RefundInfo' }
    & Pick<RefundInfo, 'refundIssuedDate' | 'refundSettledDate'>
  )>>, coupons?: Maybe<Array<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'ticketDocumentNumber' | 'ticketingDateOfIssue' | 'couponNumber'>
  )>>, ticketDocumentInfo: (
    { __typename?: 'TicketDocumentInfo' }
    & Pick<TicketDocumentInfo, 'documentType' | 'orderDocumentType' | 'tourCode' | 'summaryReferenceNumber' | 'originalDocumentNumber'>
  ), passenger?: Maybe<(
    { __typename?: 'Passenger' }
    & Pick<Passenger, 'firstName'>
    & { frequentFlyers?: Maybe<Array<(
      { __typename?: 'FrequentFlyer' }
      & Pick<FrequentFlyer, 'airline' | 'referenceNumber'>
    )>> }
  )>, history: Array<(
    { __typename: 'IssuedHistory' }
    & Pick<IssuedHistory, 'historyType' | 'date' | 'reason' | 'bookingAgent'>
  ) | (
    { __typename: 'SettledHistory' }
    & Pick<SettledHistory, 'historyType' | 'date' | 'reason' | 'referenceNumber'>
  ) | (
    { __typename: 'RefundedHistory' }
    & Pick<RefundedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'ExchangedHistory' }
    & Pick<ExchangedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'VoidedHistory' }
    & Pick<VoidedHistory, 'historyType' | 'date' | 'reason'>
  ) | (
    { __typename: 'CancelledHistory' }
    & Pick<CancelledHistory, 'historyType' | 'date' | 'reason'>
  )> }
);

export type ResultsTableTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'transactionType' | 'dateOfIssue' | 'primaryDocumentNumber' | 'esac' | 'esacSource' | 'referenceNumber' | 'issuingArcNumber' | 'source' | 'eventSource' | 'carrierCode' | 'arcNumber' | 'settledOutOfPeriod' | 'systemProvider' | 'settledDate' | 'lockedDown' | 'voided'>
  & { oldDocuments?: Maybe<Array<Maybe<(
    { __typename?: 'NewDocument' }
    & Pick<NewDocument, 'documentNumber' | 'dateOfIssue' | 'bookingAgentId' | 'remarks' | 'bankExchangeRate' | 'certificateNumbers' | 'pnrReference' | 'originalSaleDateOfIssue'>
    & { payments?: Maybe<Array<Maybe<(
      { __typename?: 'NewPayment' }
      & Pick<NewPayment, 'formOfPaymentAccountNumber' | 'formOfPaymentType' | 'creditPaymentType'>
    )>>>, prices?: Maybe<(
      { __typename?: 'NewPrice' }
      & { ticketDocumentAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )> }
    )>, taxes?: Maybe<Array<Maybe<(
      { __typename?: 'NewTax' }
      & Pick<NewTax, 'code' | 'subCode'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      ) }
    )>>>, fees?: Maybe<Array<Maybe<(
      { __typename?: 'NewFee' }
      & Pick<NewFee, 'code' | 'subCode'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      ) }
    )>>>, commissions?: Maybe<Array<Maybe<(
      { __typename?: 'NewCommission' }
      & Pick<NewCommission, 'rate' | 'commissionType'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      ) }
    )>>>, aadInfo?: Maybe<(
      { __typename?: 'NewAADInfo' }
      & { taxes: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), commissionAmount: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), commissionPercent: (
        { __typename?: 'NewAADAdjustedNumber' }
        & Pick<NewAadAdjustedNumber, 'listedAs' | 'needsToBe'>
      ), documentTotal: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), baseFare: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), netRemit: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ) }
    )>, passenger?: Maybe<(
      { __typename?: 'NewPassenger' }
      & Pick<NewPassenger, 'customerIdentification' | 'firstName' | 'lastName' | 'dateOfBirth'>
      & { frequentFlyers?: Maybe<Array<(
        { __typename?: 'NewFrequentFlyer' }
        & Pick<NewFrequentFlyer, 'airline' | 'referenceNumber'>
      )>> }
    )>, coupons?: Maybe<Array<Maybe<(
      { __typename?: 'NewCoupon' }
      & Pick<NewCoupon, 'ticketDocumentNumber' | 'couponNumber' | 'fareBasis' | 'additionalServicesAttributeGroup' | 'ticketDesignator' | 'remarks' | 'soldAirlineDesignator'>
      & { value?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )> }
    )>>>, documentInfo?: Maybe<(
      { __typename?: 'NewDocumentInfo' }
      & Pick<NewDocumentInfo, 'documentType'>
      & { inConnectionWith?: Maybe<(
        { __typename?: 'NewInConnectionWith' }
        & Pick<NewInConnectionWith, 'checkDigit' | 'couponNumbers' | 'dateOfIssue' | 'documentNumber' | 'relationship'>
      )> }
    )> }
  )>>>, newDocuments?: Maybe<Array<Maybe<(
    { __typename?: 'NewDocument' }
    & Pick<NewDocument, 'documentNumber' | 'dateOfIssue' | 'bookingAgentId' | 'remarks' | 'bankExchangeRate' | 'certificateNumbers' | 'pnrReference' | 'originalSaleDateOfIssue'>
    & { payments?: Maybe<Array<Maybe<(
      { __typename?: 'NewPayment' }
      & Pick<NewPayment, 'formOfPaymentAccountNumber' | 'formOfPaymentType' | 'creditPaymentType'>
    )>>>, prices?: Maybe<(
      { __typename?: 'NewPrice' }
      & { ticketDocumentAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )> }
    )>, taxes?: Maybe<Array<Maybe<(
      { __typename?: 'NewTax' }
      & Pick<NewTax, 'code' | 'subCode'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      ) }
    )>>>, fees?: Maybe<Array<Maybe<(
      { __typename?: 'NewFee' }
      & Pick<NewFee, 'code' | 'subCode'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      ) }
    )>>>, commissions?: Maybe<Array<Maybe<(
      { __typename?: 'NewCommission' }
      & Pick<NewCommission, 'rate' | 'commissionType'>
      & { value: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      ) }
    )>>>, aadInfo?: Maybe<(
      { __typename?: 'NewAADInfo' }
      & { taxes: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), commissionAmount: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), commissionPercent: (
        { __typename?: 'NewAADAdjustedNumber' }
        & Pick<NewAadAdjustedNumber, 'listedAs' | 'needsToBe'>
      ), documentTotal: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), baseFare: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ), netRemit: (
        { __typename?: 'NewAADAdjustedAmount' }
        & { listedAs: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ), needsToBe: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'precision' | 'currency'>
        ) }
      ) }
    )>, passenger?: Maybe<(
      { __typename?: 'NewPassenger' }
      & Pick<NewPassenger, 'customerIdentification' | 'firstName' | 'lastName' | 'dateOfBirth'>
      & { frequentFlyers?: Maybe<Array<(
        { __typename?: 'NewFrequentFlyer' }
        & Pick<NewFrequentFlyer, 'airline' | 'referenceNumber'>
      )>> }
    )>, coupons?: Maybe<Array<Maybe<(
      { __typename?: 'NewCoupon' }
      & Pick<NewCoupon, 'ticketDocumentNumber' | 'couponNumber' | 'fareBasis' | 'additionalServicesAttributeGroup' | 'ticketDesignator' | 'remarks' | 'soldAirlineDesignator'>
      & { value?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'precision' | 'currency'>
      )> }
    )>>>, documentInfo?: Maybe<(
      { __typename?: 'NewDocumentInfo' }
      & Pick<NewDocumentInfo, 'documentType'>
      & { inConnectionWith?: Maybe<(
        { __typename?: 'NewInConnectionWith' }
        & Pick<NewInConnectionWith, 'checkDigit' | 'couponNumbers' | 'dateOfIssue' | 'documentNumber' | 'relationship'>
      )> }
    )> }
  )>>>, netRemit?: Maybe<(
    { __typename?: 'NewNetRemit' }
    & Pick<NewNetRemit, 'extractDate' | 'reportPeriodEndDate' | 'periodNumber'>
    & { value: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'precision' | 'currency'>
    ) }
  )>, refundInfo?: Maybe<(
    { __typename?: 'NewRefundInfo' }
    & Pick<NewRefundInfo, 'couponReferences' | 'indicator' | 'endorsementRestriction' | 'waiverCode' | 'unmatchedRefundIndicator'>
    & { refundedAmount: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ), dueAmount: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ) }
  )>, voidInfo?: Maybe<(
    { __typename?: 'NewVoidInfo' }
    & Pick<NewVoidInfo, 'wavierCode' | 'unmatchedVoidIndicator' | 'unappliedVoidIndicator' | 'issuedDate' | 'voidDate' | 'originalTransactionType'>
  )> }
);

export type SearchTransactionFragment = (
  { __typename?: 'Transaction' }
  & ResultsTableTransactionFragment
);

export type TransactionSearchQueryVariables = Exact<{
  arcNumber?: Maybe<Scalars['String']>;
  airlineNumCode?: Maybe<Scalars['String']>;
  dateOfIssue?: Maybe<Scalars['String']>;
  documentNumbers?: Maybe<Scalars['String']>;
  passengerLastName?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
}>;


export type TransactionSearchQuery = (
  { __typename?: 'Query' }
  & { searchTransactions: (
    { __typename?: 'SearchTransactionResponse' }
    & Pick<SearchTransactionResponse, 'total'>
    & { data: Array<Maybe<(
      { __typename?: 'Transaction' }
      & SearchTransactionFragment
    )>> }
  ) }
);

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = (
  { __typename?: 'Query' }
  & { versions: Array<(
    { __typename?: 'VersionInfo' }
    & VersionInfoFragment
  )> }
);

export type VersionInfoFragment = (
  { __typename?: 'VersionInfo' }
  & Pick<VersionInfo, 'buildNumber' | 'commitHash' | 'serviceName' | 'branchName'>
);

export const ResultsTableItemFragmentDoc = gql`
    fragment ResultsTableItem on Item {
  fullDocumentNumber
  primaryDocumentNumber
  itemId
  passengerName
  dateOfIssue
  settledDate
  refundSettledDate
  transactionType
  void
  voidInfo {
    issuedDate
    voidDate
    eventSource
  }
  exchangeInfo {
    exchangeIssuedDate
    exchangeSettledDate
    value {
      amount
      currency
      precision
    }
  }
  exchange {
    thread {
      id
      isRootExchange
    }
  }
  agency {
    arcNumber
  }
  airline {
    numCode
  }
  payments {
    formOfPaymentAccountNumber
    formOfPaymentType
    creditPaymentType
    exchangedDocumentReferences {
      coupons
      documentNumber
      indicator
    }
  }
  paymentDisplay
  refunds {
    couponReferences
    indicator
    refundIssuedDate
    refundSettledDate
    dueAmount {
      amount
      currency
      precision
    }
    refundedAmount {
      amount
      currency
      precision
    }
    eventSource
  }
  netRemitTotal {
    amount
    currency
    precision
  }
  coupons {
    ticketDocumentNumber
    ticketingDateOfIssue
    couponNumber
    status
    soldAirlineDesignator
    value {
      amount
      currency
      precision
    }
  }
  prices {
    ticketDocumentAmount {
      amount
      precision
      currency
    }
  }
  ticketDocumentInfo {
    documentType
    orderDocumentType
  }
  history {
    ... on ItemHistory {
      __typename
      historyType
      date
      reason
    }
    ... on RefundedHistory {
      esacNumber
      issuingArcNumber
    }
    ... on ExchangedHistory {
      esacNumber
      issuingArcNumber
    }
    ... on IssuedHistory {
      bookingAgent
    }
    ... on SettledHistory {
      referenceNumber
    }
    ... on VoidedHistory {
      esacNumber
      esacSource
      issuingArcNumber
      voidType
    }
    ... on CancelledHistory {
      esacNumber
      issuingArcNumber
    }
  }
}
    `;
export const ExchangeThreadExchangeFragmentDoc = gql`
    fragment ExchangeThreadExchange on Exchange {
  id
  exchangedCouponNumbers
  exchangeIndicator
  isRootExchange
  parentExchange {
    id
  }
  taxes {
    value {
      amount
      precision
      currency
      display
    }
    code
    subCode
  }
  netValue {
    amount
    precision
    currency
  }
  exchangedItems {
    ...ResultsTableItem
  }
  resolvedItems {
    ...ResultsTableItem
  }
}
    ${ResultsTableItemFragmentDoc}`;
export const SearchItemFragmentDoc = gql`
    fragment SearchItem on Item {
  ...ResultsTableItem
  exchange {
    thread {
      ...ExchangeThreadExchange
    }
  }
}
    ${ResultsTableItemFragmentDoc}
${ExchangeThreadExchangeFragmentDoc}`;
export const FormOfPaymentInformationItemFragmentDoc = gql`
    fragment FormOfPaymentInformationItem on Item {
  transactionType
  payments {
    creditPaymentType
    approvalCode
    formOfPaymentType
    formOfPaymentAccountNumber
  }
  paymentDisplay
  netRemitTotal {
    amount
    currency
    precision
  }
}
    `;
export const TaxesInformationTaxFragmentDoc = gql`
    fragment TaxesInformationTax on Tax {
  value {
    amount
    precision
    currency
    display
  }
  code
  subCode
  refund
}
    `;
export const FinancialInformationRefundItemFragmentDoc = gql`
    fragment FinancialInformationRefundItem on Item {
  ...FormOfPaymentInformationItem
  refundNetRemitTotal {
    amount
    currency
    precision
  }
  refundPrices {
    baseFareAmount {
      display
    }
  }
  refundCommission {
    old {
      value {
        amount
        display
        precision
        currency
      }
      commissionType
      refund
    }
    adminPenalty {
      value {
        amount
        display
        precision
        currency
      }
      commissionType
      refund
    }
  }
  refundTaxes {
    ...TaxesInformationTax
  }
  adminPenaltyFee {
    value {
      amount
      currency
      precision
    }
    code
    subCode
  }
  refunds {
    dueAmount {
      display
    }
    refundedAmount {
      display
    }
    waiverCode
    eventSource
  }
}
    ${FormOfPaymentInformationItemFragmentDoc}
${TaxesInformationTaxFragmentDoc}`;
export const CommissionInformationItemFragmentDoc = gql`
    fragment CommissionInformationItem on Item {
  transactionType
  aadInfo {
    commissionPercent {
      listedAs
      needsToBe
    }
    commissionAmount {
      listedAs {
        amount
        currency
        precision
      }
      needsToBe {
        amount
        currency
        precision
      }
    }
  }
  prices {
    baseFareAmount {
      amount
      precision
      currency
    }
  }
  commission {
    rate
    value {
      amount
      precision
      currency
    }
    refund
  }
}
    `;
export const FinancialInformationArcPayItemFragmentDoc = gql`
    fragment FinancialInformationArcPayItem on Item {
  ...FormOfPaymentInformationItem
  ...CommissionInformationItem
  airline {
    numCode
    designator
    dbaName
  }
  agency {
    arcNumber
  }
  passenger {
    frequentFlyers {
      referenceNumber
    }
  }
  certificateNumbers
  dateOfIssue
  settledDate
  pnrReference
  history {
    ... on ItemHistory {
      __typename
      historyType
      date
      reason
    }
    ... on IssuedHistory {
      __typename
      bookingAgent
      historyType
      date
      reason
    }
  }
  passengerName
  fullDocumentNumber
  prices {
    ticketDocumentAmount {
      display
    }
    baseFareAmount {
      display
    }
  }
  payments {
    formOfPaymentAmount {
      amount
      currency
      precision
      display
    }
  }
}
    ${FormOfPaymentInformationItemFragmentDoc}
${CommissionInformationItemFragmentDoc}`;
export const FinancialInformationCommissionRecallItemFragmentDoc = gql`
    fragment FinancialInformationCommissionRecallItem on Item {
  ...FormOfPaymentInformationItem
}
    ${FormOfPaymentInformationItemFragmentDoc}`;
export const FinancialInformationCreditDebitMemoItemFragmentDoc = gql`
    fragment FinancialInformationCreditDebitMemoItem on Item {
  ...FormOfPaymentInformationItem
  transactionType
}
    ${FormOfPaymentInformationItemFragmentDoc}`;
export const FinancialInformationAgentDeductionItemFragmentDoc = gql`
    fragment FinancialInformationAgentDeductionItem on Item {
  ...CommissionInformationItem
  aadInfo {
    taxes {
      listedAs {
        amount
        precision
        currency
        display
      }
      needsToBe {
        amount
        precision
        currency
        display
      }
    }
    commissionAmount {
      listedAs {
        amount
        precision
        currency
        display
      }
      needsToBe {
        amount
        precision
        currency
        display
      }
    }
    commissionPercent {
      listedAs
      needsToBe
    }
    documentTotal {
      listedAs {
        amount
        precision
        currency
        display
      }
      needsToBe {
        amount
        precision
        currency
        display
      }
    }
    baseFare {
      listedAs {
        amount
        precision
        currency
        display
      }
      needsToBe {
        amount
        precision
        currency
        display
      }
    }
    netRemit {
      listedAs {
        amount
        precision
        currency
        display
      }
      needsToBe {
        amount
        precision
        currency
        display
      }
    }
  }
}
    ${CommissionInformationItemFragmentDoc}`;
export const FinancialInformationTicketItemFragmentDoc = gql`
    fragment FinancialInformationTicketItem on Item {
  ...FormOfPaymentInformationItem
  ...CommissionInformationItem
  ticketDocumentInfo {
    tourCode
    fareCalculationArea
    waiverCodeCertificate
    internationalSaleIndicator
    fareCalculationPricingIndicator
    fareCalculationModeIndicator
    endorsementRestriction
  }
  airline {
    numCode
    designator
    dbaName
  }
  agency {
    arcNumber
  }
  passenger {
    frequentFlyers {
      referenceNumber
    }
  }
  certificateNumbers
  dateOfIssue
  settledDate
  pnrReference
  history {
    ... on ItemHistory {
      __typename
      historyType
      date
      reason
    }
    ... on IssuedHistory {
      __typename
      bookingAgent
      historyType
      date
      reason
    }
  }
  passengerName
  fullDocumentNumber
  taxes {
    ...TaxesInformationTax
  }
  prices {
    ticketDocumentAmount {
      display
    }
    baseFareAmount {
      display
    }
  }
  netRemitTotal {
    amount
    currency
    precision
  }
}
    ${FormOfPaymentInformationItemFragmentDoc}
${CommissionInformationItemFragmentDoc}
${TaxesInformationTaxFragmentDoc}`;
export const CostOfExchangedDocumentsExchangeFragmentDoc = gql`
    fragment CostOfExchangedDocumentsExchange on Exchange {
  taxes {
    ...TaxesInformationTax
  }
  costOfOldDocuments {
    amount
    currency
    precision
  }
  exchangedItems {
    fullDocumentNumber
    prices {
      baseFareAmount {
        amount
        precision
        currency
      }
      ticketDocumentAmount {
        amount
        precision
        currency
      }
    }
  }
}
    ${TaxesInformationTaxFragmentDoc}`;
export const CostOfNewDocumentsExchangeFragmentDoc = gql`
    fragment CostOfNewDocumentsExchange on Exchange {
  taxes {
    ...TaxesInformationTax
  }
  costOfNewDocuments {
    amount
    currency
    precision
  }
  resolvedItems {
    fullDocumentNumber
    prices {
      baseFareAmount {
        amount
        precision
        currency
      }
      ticketDocumentAmount {
        amount
        precision
        currency
      }
    }
    taxes {
      value {
        amount
        precision
        currency
        display
      }
      code
      subCode
    }
  }
}
    ${TaxesInformationTaxFragmentDoc}`;
export const FinancialInformationExchangeExchangeFragmentDoc = gql`
    fragment FinancialInformationExchangeExchange on Exchange {
  ...CostOfExchangedDocumentsExchange
  ...CostOfNewDocumentsExchange
  exchangedCouponNumbers
  exchangeIndicator
  netValue {
    amount
    precision
    currency
  }
  resolvedItems {
    transactionType
    ticketDocumentInfo {
      waiverCodeCertificate
    }
    adminPenaltyFee {
      value {
        amount
        currency
        precision
      }
      code
      subCode
    }
    payments {
      creditPaymentType
      approvalCode
      formOfPaymentType
      formOfPaymentAccountNumber
    }
    paymentDisplay
    netRemitTotal {
      amount
      currency
      precision
    }
  }
  commission {
    old {
      commissionType
      value {
        display
        amount
        currency
        precision
      }
    }
    new {
      commissionType
      value {
        display
        amount
        currency
        precision
      }
    }
    adminPenalty {
      commissionType
      value {
        display
        amount
        currency
        precision
      }
    }
  }
}
    ${CostOfExchangedDocumentsExchangeFragmentDoc}
${CostOfNewDocumentsExchangeFragmentDoc}`;
export const FinancialInformationItemFragmentDoc = gql`
    fragment FinancialInformationItem on Item {
  ...FinancialInformationRefundItem
  ...FinancialInformationArcPayItem
  ...FinancialInformationCommissionRecallItem
  ...FinancialInformationCreditDebitMemoItem
  ...FinancialInformationAgentDeductionItem
  ...FinancialInformationTicketItem
  primaryDocumentNumber
  fullDocumentNumber
  transactionType
  refunds {
    dateOfIssue
    indicator
    couponReferences
  }
  exchange {
    thread {
      id
      exchangeIssuedDate
      exchangedItems {
        primaryDocumentNumber
      }
      resolvedItems {
        primaryDocumentNumber
      }
      ...FinancialInformationExchangeExchange
    }
  }
}
    ${FinancialInformationRefundItemFragmentDoc}
${FinancialInformationArcPayItemFragmentDoc}
${FinancialInformationCommissionRecallItemFragmentDoc}
${FinancialInformationCreditDebitMemoItemFragmentDoc}
${FinancialInformationAgentDeductionItemFragmentDoc}
${FinancialInformationTicketItemFragmentDoc}
${FinancialInformationExchangeExchangeFragmentDoc}`;
export const PfcInformationTaxFragmentDoc = gql`
    fragment PFCInformationTax on Tax {
  value {
    amount
    precision
    currency
    display
  }
  code
  subCode
  refund
}
    `;
export const HistoryRowHistoryFragmentDoc = gql`
    fragment HistoryRowHistory on ItemHistories {
  ... on ItemHistory {
    __typename
    historyType
    date
    reason
  }
  ... on RefundedHistory {
    esacNumber
    issuingArcNumber
  }
  ... on ExchangedHistory {
    esacNumber
    issuingArcNumber
  }
  ... on IssuedHistory {
    bookingAgent
    systemProvider
  }
  ... on SettledHistory {
    referenceNumber
    settledOutOfPeriod
  }
  ... on VoidedHistory {
    esacSource
    esacNumber
    issuingArcNumber
    voidType
  }
  ... on CancelledHistory {
    esacNumber
    issuingArcNumber
  }
}
    `;
export const ItemHistoryItemFragmentDoc = gql`
    fragment ItemHistoryItem on Item {
  history {
    ...HistoryRowHistory
  }
  transactionType
}
    ${HistoryRowHistoryFragmentDoc}`;
export const CouponCouponFragmentDoc = gql`
    fragment CouponCoupon on Coupon {
  stopoverCode
  couponNumber
  soldFlightNumber
  soldAirlineDesignator
  soldOriginCityCode
  soldDestinationCityCode
  soldFlightDepartureDate
  soldFlightDepartureDateTime
  soldFlightDepartureTime
  soldReservationBookingDesignator
  fareBasis
  ticketDesignator
  status
  classOfService
}
    `;
export const ItineraryItemFragmentDoc = gql`
    fragment ItineraryItem on Item {
  coupons {
    ...CouponCoupon
  }
  void
  ticketDocumentInfo {
    endorsementRestriction
    orderDocumentType
  }
}
    ${CouponCouponFragmentDoc}`;
export const RelatedDocumentsItemFragmentDoc = gql`
    fragment RelatedDocumentsItem on Item {
  exchange {
    thread {
      ...ExchangeThreadExchange
    }
  }
}
    ${ExchangeThreadExchangeFragmentDoc}`;
export const TicketCardItemFragmentDoc = gql`
    fragment TicketCardItem on Item {
  fullDocumentNumber
  primaryDocumentNumber
  passengerName
  dateOfIssue
  settledDate
  refundSettledDate
  pnrReference
  void
  transactionType
  voidInfo {
    issuedDate
    voidDate
    eventSource
  }
  exchangeInfo {
    exchangeIssuedDate
    exchangeSettledDate
  }
  agency {
    arcNumber
    dbaName
  }
  airline {
    numCode
    designator
    dbaName
  }
  payments {
    formOfPaymentType
  }
  refunds {
    refundIssuedDate
    refundSettledDate
  }
  coupons {
    ticketDocumentNumber
    ticketingDateOfIssue
    couponNumber
  }
  ticketDocumentInfo {
    documentType
    orderDocumentType
    tourCode
    summaryReferenceNumber
    originalDocumentNumber
  }
  passenger {
    firstName
    frequentFlyers {
      airline
      referenceNumber
    }
  }
  history {
    ... on ItemHistory {
      __typename
      historyType
      date
      reason
    }
    ... on IssuedHistory {
      bookingAgent
    }
    ... on SettledHistory {
      referenceNumber
    }
  }
}
    `;
export const ResultsTableTransactionFragmentDoc = gql`
    fragment ResultsTableTransaction on Transaction {
  id
  transactionType
  dateOfIssue
  primaryDocumentNumber
  esac
  esacSource
  referenceNumber
  issuingArcNumber
  source
  eventSource
  carrierCode
  arcNumber
  settledOutOfPeriod
  systemProvider
  settledDate
  oldDocuments {
    documentNumber
    dateOfIssue
    bookingAgentId
    remarks
    bankExchangeRate
    certificateNumbers
    pnrReference
    payments {
      formOfPaymentAccountNumber
      formOfPaymentType
      creditPaymentType
    }
    prices {
      ticketDocumentAmount {
        amount
        precision
        currency
      }
    }
    taxes {
      value {
        amount
        precision
        currency
      }
      code
      subCode
    }
    fees {
      value {
        amount
        precision
        currency
      }
      code
      subCode
    }
    commissions {
      value {
        amount
        precision
        currency
      }
      rate
      commissionType
    }
    aadInfo {
      taxes {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      commissionAmount {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      commissionPercent {
        listedAs
        needsToBe
      }
      documentTotal {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      baseFare {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      netRemit {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
    }
    passenger {
      customerIdentification
      firstName
      lastName
      dateOfBirth
      frequentFlyers {
        airline
        referenceNumber
      }
    }
    coupons {
      ticketDocumentNumber
      couponNumber
      value {
        amount
        precision
        currency
      }
      fareBasis
      additionalServicesAttributeGroup
      ticketDesignator
      remarks
      soldAirlineDesignator
      value {
        amount
        precision
        currency
      }
    }
    originalSaleDateOfIssue
    documentInfo {
      documentType
      inConnectionWith {
        checkDigit
        couponNumbers
        dateOfIssue
        documentNumber
        relationship
      }
    }
  }
  newDocuments {
    documentNumber
    dateOfIssue
    bookingAgentId
    remarks
    bankExchangeRate
    certificateNumbers
    pnrReference
    payments {
      formOfPaymentAccountNumber
      formOfPaymentType
      creditPaymentType
    }
    prices {
      ticketDocumentAmount {
        amount
        precision
        currency
      }
    }
    taxes {
      value {
        amount
        precision
        currency
      }
      code
      subCode
    }
    fees {
      value {
        amount
        precision
        currency
      }
      code
      subCode
    }
    commissions {
      value {
        amount
        precision
        currency
      }
      rate
      commissionType
    }
    aadInfo {
      taxes {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      commissionAmount {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      commissionPercent {
        listedAs
        needsToBe
      }
      documentTotal {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      baseFare {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
      netRemit {
        listedAs {
          amount
          precision
          currency
        }
        needsToBe {
          amount
          precision
          currency
        }
      }
    }
    passenger {
      customerIdentification
      firstName
      lastName
      dateOfBirth
      frequentFlyers {
        airline
        referenceNumber
      }
    }
    coupons {
      ticketDocumentNumber
      couponNumber
      value {
        amount
        precision
        currency
      }
      fareBasis
      additionalServicesAttributeGroup
      ticketDesignator
      remarks
      soldAirlineDesignator
      value {
        amount
        precision
        currency
      }
    }
    originalSaleDateOfIssue
    documentInfo {
      documentType
      inConnectionWith {
        checkDigit
        couponNumbers
        dateOfIssue
        documentNumber
        relationship
      }
    }
  }
  netRemit {
    extractDate
    reportPeriodEndDate
    value {
      amount
      precision
      currency
    }
    periodNumber
  }
  refundInfo {
    couponReferences
    indicator
    endorsementRestriction
    refundedAmount {
      amount
      currency
      precision
    }
    dueAmount {
      amount
      currency
      precision
    }
    waiverCode
    unmatchedRefundIndicator
  }
  lockedDown
  voidInfo {
    wavierCode
    unmatchedVoidIndicator
    unappliedVoidIndicator
    issuedDate
    voidDate
    originalTransactionType
  }
  voided
}
    `;
export const SearchTransactionFragmentDoc = gql`
    fragment SearchTransaction on Transaction {
  ...ResultsTableTransaction
}
    ${ResultsTableTransactionFragmentDoc}`;
export const VersionInfoFragmentDoc = gql`
    fragment VersionInfo on VersionInfo {
  buildNumber
  commitHash
  serviceName
  branchName
}
    `;
export const CreateExportDocument = gql`
    mutation CreateExport($arcNumber: String, $airlineNumCode: String, $dateOfIssue: String, $documentNumbers: String, $itemId: String, $orderDocumentTypes: String, $passengerLastName: String, $paymentType: String, $recordLocator: String, $reportingPeriod: String, $settledDate: String, $transactionType: String, $size: String, $from: String, $fileType: ExportFileType!, $showCommissions: Boolean!, $showPassengerName: Boolean!, $showRelatedDocs: Boolean!, $fileName: String!) {
  createExportMutation(createExportRequest: {searchArgs: {arcNumber: $arcNumber, airlineNumCode: $airlineNumCode, dateOfIssue: $dateOfIssue, documentNumbers: $documentNumbers, itemId: $itemId, orderDocumentTypes: $orderDocumentTypes, passengerLastName: $passengerLastName, paymentType: $paymentType, recordLocator: $recordLocator, reportingPeriod: $reportingPeriod, settledDate: $settledDate, transactionType: $transactionType, size: $size, from: $from}, exportOptions: {showCommissions: $showCommissions, showPassengerName: $showPassengerName, showRelatedDocs: $showRelatedDocs}, fileType: $fileType, fileName: $fileName}) {
    executionId
    status
    downloadUrl
  }
}
    `;
export type CreateExportMutationFn = ApolloReactCommon.MutationFunction<CreateExportMutation, CreateExportMutationVariables>;

/**
 * __useCreateExportMutation__
 *
 * To run a mutation, you first call `useCreateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportMutation, { data, loading, error }] = useCreateExportMutation({
 *   variables: {
 *      arcNumber: // value for 'arcNumber'
 *      airlineNumCode: // value for 'airlineNumCode'
 *      dateOfIssue: // value for 'dateOfIssue'
 *      documentNumbers: // value for 'documentNumbers'
 *      itemId: // value for 'itemId'
 *      orderDocumentTypes: // value for 'orderDocumentTypes'
 *      passengerLastName: // value for 'passengerLastName'
 *      paymentType: // value for 'paymentType'
 *      recordLocator: // value for 'recordLocator'
 *      reportingPeriod: // value for 'reportingPeriod'
 *      settledDate: // value for 'settledDate'
 *      transactionType: // value for 'transactionType'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *      fileType: // value for 'fileType'
 *      showCommissions: // value for 'showCommissions'
 *      showPassengerName: // value for 'showPassengerName'
 *      showRelatedDocs: // value for 'showRelatedDocs'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExportMutation, CreateExportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExportMutation, CreateExportMutationVariables>(CreateExportDocument, baseOptions);
      }
export type CreateExportMutationHookResult = ReturnType<typeof useCreateExportMutation>;
export type CreateExportMutationResult = ApolloReactCommon.MutationResult<CreateExportMutation>;
export type CreateExportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExportMutation, CreateExportMutationVariables>;
export const ExportStatusDocument = gql`
    query ExportStatus($executionId: String!) {
  exportStatus(executionId: $executionId) {
    executionId
    status
    downloadUrl
  }
}
    `;

/**
 * __useExportStatusQuery__
 *
 * To run a query within a React component, call `useExportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportStatusQuery({
 *   variables: {
 *      executionId: // value for 'executionId'
 *   },
 * });
 */
export function useExportStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportStatusQuery, ExportStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportStatusQuery, ExportStatusQueryVariables>(ExportStatusDocument, baseOptions);
      }
export function useExportStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportStatusQuery, ExportStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportStatusQuery, ExportStatusQueryVariables>(ExportStatusDocument, baseOptions);
        }
export type ExportStatusQueryHookResult = ReturnType<typeof useExportStatusQuery>;
export type ExportStatusLazyQueryHookResult = ReturnType<typeof useExportStatusLazyQuery>;
export type ExportStatusQueryResult = ApolloReactCommon.QueryResult<ExportStatusQuery, ExportStatusQueryVariables>;
export const AgencySearchInputDocument = gql`
    query AgencySearchInput($searchTerm: String!, $limit: Int, $cursor: String) {
  agencies(search: $searchTerm, limit: $limit, cursor: $cursor) {
    data {
      id
      arcNumber
      dbaName
    }
    cursor
  }
}
    `;

/**
 * __useAgencySearchInputQuery__
 *
 * To run a query within a React component, call `useAgencySearchInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencySearchInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencySearchInputQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAgencySearchInputQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgencySearchInputQuery, AgencySearchInputQueryVariables>) {
        return ApolloReactHooks.useQuery<AgencySearchInputQuery, AgencySearchInputQueryVariables>(AgencySearchInputDocument, baseOptions);
      }
export function useAgencySearchInputLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgencySearchInputQuery, AgencySearchInputQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgencySearchInputQuery, AgencySearchInputQueryVariables>(AgencySearchInputDocument, baseOptions);
        }
export type AgencySearchInputQueryHookResult = ReturnType<typeof useAgencySearchInputQuery>;
export type AgencySearchInputLazyQueryHookResult = ReturnType<typeof useAgencySearchInputLazyQuery>;
export type AgencySearchInputQueryResult = ApolloReactCommon.QueryResult<AgencySearchInputQuery, AgencySearchInputQueryVariables>;
export const AirlineSearchInputDocument = gql`
    query AirlineSearchInput {
  airlines {
    id
    numCode
    dbaName
  }
}
    `;

/**
 * __useAirlineSearchInputQuery__
 *
 * To run a query within a React component, call `useAirlineSearchInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useAirlineSearchInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAirlineSearchInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useAirlineSearchInputQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AirlineSearchInputQuery, AirlineSearchInputQueryVariables>) {
        return ApolloReactHooks.useQuery<AirlineSearchInputQuery, AirlineSearchInputQueryVariables>(AirlineSearchInputDocument, baseOptions);
      }
export function useAirlineSearchInputLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AirlineSearchInputQuery, AirlineSearchInputQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AirlineSearchInputQuery, AirlineSearchInputQueryVariables>(AirlineSearchInputDocument, baseOptions);
        }
export type AirlineSearchInputQueryHookResult = ReturnType<typeof useAirlineSearchInputQuery>;
export type AirlineSearchInputLazyQueryHookResult = ReturnType<typeof useAirlineSearchInputLazyQuery>;
export type AirlineSearchInputQueryResult = ApolloReactCommon.QueryResult<AirlineSearchInputQuery, AirlineSearchInputQueryVariables>;
export const DocumentSearchDocument = gql`
    query DocumentSearch($arcNumber: String, $airlineNumCode: String, $dateOfIssue: String, $documentNumbers: String, $itemId: String, $orderDocumentTypes: String, $passengerLastName: String, $paymentType: String, $recordLocator: String, $reportingPeriod: String, $settledDate: String, $transactionType: String, $size: String, $from: String) {
  searchedItems(arcNumber: $arcNumber, airlineNumCode: $airlineNumCode, dateOfIssue: $dateOfIssue, documentNumbers: $documentNumbers, itemId: $itemId, orderDocumentTypes: $orderDocumentTypes, passengerLastName: $passengerLastName, paymentType: $paymentType, recordLocator: $recordLocator, reportingPeriod: $reportingPeriod, settledDate: $settledDate, transactionType: $transactionType, size: $size, from: $from) {
    data {
      ...SearchItem
    }
    total
  }
}
    ${SearchItemFragmentDoc}`;

/**
 * __useDocumentSearchQuery__
 *
 * To run a query within a React component, call `useDocumentSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSearchQuery({
 *   variables: {
 *      arcNumber: // value for 'arcNumber'
 *      airlineNumCode: // value for 'airlineNumCode'
 *      dateOfIssue: // value for 'dateOfIssue'
 *      documentNumbers: // value for 'documentNumbers'
 *      itemId: // value for 'itemId'
 *      orderDocumentTypes: // value for 'orderDocumentTypes'
 *      passengerLastName: // value for 'passengerLastName'
 *      paymentType: // value for 'paymentType'
 *      recordLocator: // value for 'recordLocator'
 *      reportingPeriod: // value for 'reportingPeriod'
 *      settledDate: // value for 'settledDate'
 *      transactionType: // value for 'transactionType'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useDocumentSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentSearchQuery, DocumentSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentSearchQuery, DocumentSearchQueryVariables>(DocumentSearchDocument, baseOptions);
      }
export function useDocumentSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentSearchQuery, DocumentSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentSearchQuery, DocumentSearchQueryVariables>(DocumentSearchDocument, baseOptions);
        }
export type DocumentSearchQueryHookResult = ReturnType<typeof useDocumentSearchQuery>;
export type DocumentSearchLazyQueryHookResult = ReturnType<typeof useDocumentSearchLazyQuery>;
export type DocumentSearchQueryResult = ApolloReactCommon.QueryResult<DocumentSearchQuery, DocumentSearchQueryVariables>;
export const TransactionDetailDocument = gql`
    query TransactionDetail($id: String!) {
  item(id: $id) {
    ...ItineraryItem
    ...TicketCardItem
    ...FinancialInformationItem
    ...ItemHistoryItem
  }
  initialItem: item(id: $id, state: "no-edits") {
    ...ItineraryItem
    ...TicketCardItem
    ...FinancialInformationItem
    ...ItemHistoryItem
  }
}
    ${ItineraryItemFragmentDoc}
${TicketCardItemFragmentDoc}
${FinancialInformationItemFragmentDoc}
${ItemHistoryItemFragmentDoc}`;

/**
 * __useTransactionDetailQuery__
 *
 * To run a query within a React component, call `useTransactionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionDetailQuery, TransactionDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionDetailQuery, TransactionDetailQueryVariables>(TransactionDetailDocument, baseOptions);
      }
export function useTransactionDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionDetailQuery, TransactionDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionDetailQuery, TransactionDetailQueryVariables>(TransactionDetailDocument, baseOptions);
        }
export type TransactionDetailQueryHookResult = ReturnType<typeof useTransactionDetailQuery>;
export type TransactionDetailLazyQueryHookResult = ReturnType<typeof useTransactionDetailLazyQuery>;
export type TransactionDetailQueryResult = ApolloReactCommon.QueryResult<TransactionDetailQuery, TransactionDetailQueryVariables>;
export const TransactionDetailRelatedDocsDocument = gql`
    query TransactionDetailRelatedDocs($id: String!) {
  item(id: $id) {
    ...RelatedDocumentsItem
  }
}
    ${RelatedDocumentsItemFragmentDoc}`;

/**
 * __useTransactionDetailRelatedDocsQuery__
 *
 * To run a query within a React component, call `useTransactionDetailRelatedDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionDetailRelatedDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionDetailRelatedDocsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionDetailRelatedDocsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionDetailRelatedDocsQuery, TransactionDetailRelatedDocsQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionDetailRelatedDocsQuery, TransactionDetailRelatedDocsQueryVariables>(TransactionDetailRelatedDocsDocument, baseOptions);
      }
export function useTransactionDetailRelatedDocsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionDetailRelatedDocsQuery, TransactionDetailRelatedDocsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionDetailRelatedDocsQuery, TransactionDetailRelatedDocsQueryVariables>(TransactionDetailRelatedDocsDocument, baseOptions);
        }
export type TransactionDetailRelatedDocsQueryHookResult = ReturnType<typeof useTransactionDetailRelatedDocsQuery>;
export type TransactionDetailRelatedDocsLazyQueryHookResult = ReturnType<typeof useTransactionDetailRelatedDocsLazyQuery>;
export type TransactionDetailRelatedDocsQueryResult = ApolloReactCommon.QueryResult<TransactionDetailRelatedDocsQuery, TransactionDetailRelatedDocsQueryVariables>;
export const TransactionSearchDocument = gql`
    query TransactionSearch($arcNumber: String, $airlineNumCode: String, $dateOfIssue: String, $documentNumbers: String, $passengerLastName: String, $transactionType: String, $transactionId: String, $size: String, $from: String) {
  searchTransactions(arcNumber: $arcNumber, airlineNumCode: $airlineNumCode, dateOfIssue: $dateOfIssue, documentNumbers: $documentNumbers, passengerLastName: $passengerLastName, transactionType: $transactionType, transactionId: $transactionId, size: $size, from: $from) {
    data {
      ...SearchTransaction
    }
    total
  }
}
    ${SearchTransactionFragmentDoc}`;

/**
 * __useTransactionSearchQuery__
 *
 * To run a query within a React component, call `useTransactionSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionSearchQuery({
 *   variables: {
 *      arcNumber: // value for 'arcNumber'
 *      airlineNumCode: // value for 'airlineNumCode'
 *      dateOfIssue: // value for 'dateOfIssue'
 *      documentNumbers: // value for 'documentNumbers'
 *      passengerLastName: // value for 'passengerLastName'
 *      transactionType: // value for 'transactionType'
 *      transactionId: // value for 'transactionId'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useTransactionSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionSearchQuery, TransactionSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionSearchQuery, TransactionSearchQueryVariables>(TransactionSearchDocument, baseOptions);
      }
export function useTransactionSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionSearchQuery, TransactionSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionSearchQuery, TransactionSearchQueryVariables>(TransactionSearchDocument, baseOptions);
        }
export type TransactionSearchQueryHookResult = ReturnType<typeof useTransactionSearchQuery>;
export type TransactionSearchLazyQueryHookResult = ReturnType<typeof useTransactionSearchLazyQuery>;
export type TransactionSearchQueryResult = ApolloReactCommon.QueryResult<TransactionSearchQuery, TransactionSearchQueryVariables>;
export const VersionDocument = gql`
    query Version {
  versions {
    ...VersionInfo
  }
}
    ${VersionInfoFragmentDoc}`;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        return ApolloReactHooks.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, baseOptions);
      }
export function useVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, baseOptions);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = ApolloReactCommon.QueryResult<VersionQuery, VersionQueryVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "ItemHistories",
        "possibleTypes": [
          {
            "name": "IssuedHistory"
          },
          {
            "name": "SettledHistory"
          },
          {
            "name": "RefundedHistory"
          },
          {
            "name": "ExchangedHistory"
          },
          {
            "name": "VoidedHistory"
          },
          {
            "name": "CancelledHistory"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ItemHistory",
        "possibleTypes": [
          {
            "name": "IssuedHistory"
          },
          {
            "name": "SettledHistory"
          },
          {
            "name": "RefundedHistory"
          },
          {
            "name": "ExchangedHistory"
          },
          {
            "name": "VoidedHistory"
          },
          {
            "name": "CancelledHistory"
          }
        ]
      }
    ]
  }
};
      export default result;
    