import React from "react";
import airplane from "../../assets/icons/airplane.svg";
import bell from "../../assets/icons/bell.svg";
import briefcase from "../../assets/icons/briefcase.svg";
import down from "../../assets/icons/down.svg";
import right from "../../assets/icons/right.svg";
import search from "../../assets/icons/search.svg";
import leftArrowColor from "../../assets/icons/left-arrow-teal.svg";
import leftArrow from "../../assets/icons/left-arrow.svg";
import suitcase from "../../assets/icons/suitcase.svg";
import warning from "../../assets/icons/warning.svg";
import fileWord from "../../assets/icons/file-word.svg";
import blackCheck from "../../assets/icons/checkmark.svg";
import magentaCheck from "../../assets/icons/checkmark-magenta.svg";
import whiteCheck from "../../assets/icons/checkmark-white.svg";
import purpleCheck from "../../assets/icons/checkmark-purple.svg";
import fileMagGlass from "../../assets/icons/file-mag-glass.svg";
import searchError from "../../assets/icons/search-error.svg";
import calendar from "../../assets/icons/calendar.svg";
import circularArrow from "../../assets/icons/circular-arrow.svg";
import whiteCircularArrow from "../../assets/icons/white-circular-arrow.svg";
import arrow from "../../assets/icons/arrow.svg";
import profile from "../../assets/icons/fds-glyphs-profile.svg";
import fdsAirplane from "../../assets/icons/fds-glyphs-airplane.svg";

type CircularArrowColors = "black" | "white"
interface CircularArrowProps {
  color?: CircularArrowColors;
}
type CheckmarkColors = "black" | "magenta" | "purple" | "white";
interface CheckmarkProps {
  color: CheckmarkColors;
}
const Icon = {
  Checkmark: ({ color }: CheckmarkProps) => {
    let src = blackCheck;
    switch (color) {
      case "magenta":
        src = magentaCheck;
        break;
      case "purple":
        src = purpleCheck;
        break;
      case "white":
        src = whiteCheck;
        break;
    }
    return <img src={src} alt={"checkmark"} />;
  },
  LeftArrowColor: () => <img src={leftArrowColor} alt="left icon" />,
  Airplane: () => <img src={airplane} alt="airplane icon" />,
  Bell: () => <img src={bell} alt="bell icon" />,
  Briefcase: () => <img src={briefcase} alt="briefcase icon" />,
  Document: () => <svg />,
  Down: () => <img src={down} alt="down icon" />,
  Right: () => <img src={right} alt="right icon" />,
  Search: () => <img src={search} alt="search icon" />,
  LeftArrow: () => <img src={leftArrow} alt="left icon" />,
  Print: () => <i className="printIcon fds-glyphs-printer3"></i>,
  Download: () => <i className="export-icon fds-glyphs-download5"></i>,
  DisabledDownload: () => <i className="export-icon fds-glyphs-download5" style={{color: "silver"}}></i>,
  Suitcase: () => <img src={suitcase} alt="suitcase icon" />,
  Warning: () => <img className="resultsIcon" src={warning} alt="warning icon" />,
  File: () => <img className="resultsIcon" src={fileWord} alt="file icon" />,
  FileAndMagnifyingGlass: () => <img className="resultsIcon" src={fileMagGlass} alt="magnifying glass icon" />,
  SearchError: () => <img src={searchError} alt="search error icon" />,
  Calendar: () => <img src={calendar} alt="calendar icon" />,
  CircularArrow: ({color = "black"}: CircularArrowProps) => <img src={color === "black" ? circularArrow : whiteCircularArrow} alt="circular arrow"/>,
  Arrow: () => <img src={arrow} alt="arrow"/>,
  Profile: () => <img src={profile} alt="profile"/>,
  FdsAirplane: () => <img src={fdsAirplane} alt="fdsAirplane"/>
};

export default Icon;
