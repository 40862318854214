import React, { MouseEventHandler } from "react";
import "./IconButton.scss";

export interface IconButtonProps {
  onClick: MouseEventHandler;
  icon: () => JSX.Element;
}

export const IconButton = (props: IconButtonProps) => (
  <button
    onClick={props.onClick}
    className="icon-button"
    data-test-id="button-download"
  >
    {props.icon()}
  </button>
);
