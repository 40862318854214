import React from "react";
import { Container } from "react-bootstrap";

interface PageTitleProps {
  title: string;
  subTitle: string;
}

const PageTitle = (props: PageTitleProps) => (
  <Container className="fds-pl-header-copy">
    <div className="type-heading-h1-on-dark">{props.title}</div>
    <div className="type-heading-h2-subtitle-on-dark">{props.subTitle}</div>
  </Container>
);

export default PageTitle;
