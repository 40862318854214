import { useFeature as optimizelyUseFeature } from "@optimizely/react-sdk";

export enum Flag {
  DEV_TOOLS = "dev-tools",
  PDF_EXPORT = "pdf_export",
  MODIFY_TRANSACTIONS = "modify_transactions",
  SHOW_BACK_BUTTON = "show_back_button",
  SHOW_CONCISE_VIEW = "show_concise_view",
  EXPORT_SEARCH_RESULTS = "export_search_results"
}

export function useFeature(flag: Flag): boolean {
  return optimizelyUseFeature(flag, { autoUpdate: true })[0];
}
