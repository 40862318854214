/* eslint @typescript-eslint/no-explicit-any:0 */
import React, { Suspense } from "react";
import Header from "./Header";
import "./styles.scss";
import { Footer, LoadingSpinner } from "@arctravel/react-fds";

interface NavigationContainerProps {
  children: React.ReactNode;
}

export const Navigation = (props: NavigationContainerProps) => (
  <div className="NavigationContainer">
    <div className="NavigationHeader">
      <Header />
    </div>
    <div className="NavigationContent">
      <Suspense fallback={<div className={"navigation-loading-spinner"}><LoadingSpinner /></div>}>
        {props.children}
      </Suspense>
    </div>
    <div className="NavigationFooter d-print-none">
      <Footer />
    </div>
  </div>
);

export default Navigation;
